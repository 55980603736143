import { AccountingSchemeEnum, PeriodicityEnum, SpecialVatSchemeEnum } from "../companyQuotationWizard/dto"
import { PillsColor } from "../pills/dto"

export interface DocumentCreationDTO {
    expirationDate: string,
    note: string,
    fileName: string,
    type: string,
    companyId: number,
    period: string,
    year: string,
    activityTypeId: string
    senderGroup: 'accountants' | 'operators' | 'companies' | 'admin'
    receiverGroup: ('accountants' | 'operators' | 'companies')[]
}

export interface FindAllCompaniesFilters {
    accountant: string,
    financialAdvisor: string,
    operators: string,
    businessName: string,
    itemsPerPage: number,
    page: number,
    status: CompanyStatusEnum[]
    contabile: string
}

export enum CompanyStatusEnum {
    Pending = 'Pending',
    RegistryPending = 'RegistryPending',
    Active = 'Active'
}

export interface FindAllCompanies {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: CompanyResponseDTO[]
}

export interface CompanyResponseDTO {
    id: number,
    userId: string | null,
    email: string | null,
    businessName: string | null,
    accountingScheme: AccountingSchemeEnum,
    premium: boolean,
    periodicity: PeriodicityEnum,
    price: number,
    state: CompanyStatusEnum,
    annualForeignInvoices: number,
    atecoCodesNumber: number,
    avatarObjectId: string | null,
    activityCategoryIds: number[] | null,
    todos: string[] | null,
    professionistWithFund: boolean,
    specialVatSchemes: boolean,
    specialVatScheme: SpecialVatSchemeEnum | null,
    banksNumber: number,
    journal: number,
    financialAdvisorId: string,
    accountantId: string,
    name: string | null,
    surname: string | null,
    fiscalCode: string | null,
    vatNumber: string | null,
    note: string | null,
    employeesNumber: number | null,
    phoneNumber?: string | null,
    bankingAccounts: string[]
    activityTypeIds: number[],
    salesRegister: string[] | null
    purchasesRegister: string[] | null
    feesRegister: string[] | null
    registerCharge: boolean | null
    registerChargeName: string | null
    admins: string[] | null
    members: string[],
    atecoCodeIds: number[]
    loansObjectIds: number[] | null
    fundingObjectIds: number[] | null
    leasingObjectIds: number[] | null
    instrumentalVehicles: boolean | null,
    instrumentalVehiclesLicensePlate: string | null,
    notInstrumentalVehicles: boolean | null,
    notInstrumentalVehiclesLicensePlate: string | null,
    retentionManagement: boolean | null,
    acubeAccounts: string[],
    operatorIds: string[],
    contabileId: string,
    ivaStartMonth: number,
    cogeStartMonth: number,
    startYear: number
}

export interface CompanyUpdateRegistryDTO {
    businessName: string,
    name?: string,
    surname?: string,
    vatNumber?: string,
    fiscalCode?: string,
    email?: string,
    phoneNumber?: string,
    employeesNumber?: number,
    note?: string,
}

export interface AddOperatorDTO {
    operatorIds: number[]
}
export interface AddAtecoDTO {
    atecoCodeId: number
}
export interface AddAccountantIdDTO {
    accountantId: string
}

export interface AddContabileIdDTO {
    contabileId: string
}
export interface FindAllAtecos {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: AtecoResponseDTO[]
}
export interface AtecoResponseDTO {
    id: number,
    code: string,
    description: string,
    coefficientOfProfitability: number
}

export interface AddBankingAccountDTO {
    bankingAccounts: string[]
}

export const CompanyStatusMap = new Map<CompanyStatusEnum, { color: PillsColor, label: string }>([
    [CompanyStatusEnum.Active, { color: PillsColor.SUCCESS, label: 'Attiva' }],
    [CompanyStatusEnum.Pending, { color: PillsColor.WARNING, label: 'Da accettare' }],
    [CompanyStatusEnum.RegistryPending, { color: PillsColor.WARNING, label: 'In attesa' }]
])

export const AccountingSchemeMap = new Map<AccountingSchemeEnum, string>([
    [AccountingSchemeEnum.Ordinary, "Ordinaria"],
    [AccountingSchemeEnum.Simplified, "Semplificata"]
])
export interface BankRequestModel {
    name: string
    iban: string
}
export interface BankRequestDTO {
    bankingAccounts: string[]
}

export interface ActivityTypesRequestDTO {
    activityTypeIds: number[]
}

export interface TodosDTO {
    todos: string[]
}

export interface ActivityCanBeDeletedResponse {
    activityTypeId: number,
    canBeDeleted: boolean
}

export interface ActivityGroupDTO {
    id: number,
    dueDate: Date,
    period: number,
    year: number,
    state: ActivityGroupStatus,
    activityCategoryId: number,
    companyId: number
    activityKind: ActivityKind
}

export interface ActivityGroupResponse {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: ActivityGroupDTO[]
}

export enum ActivityGroupStatus {
    Pending = 'Pending',
    Completed = 'Completed',
    Expired = 'Expired',
}

export enum ActivityKind {
    Records = "Records",
    Controls = "Controls"
}

export enum ActivityStatus {
    Pending = 'Pending',
    Completed = 'Completed',
}

export interface ActivityDTO {
    id: number,
    state: ActivityStatus,
    activityGroupId: number,
    activityTypeId: number,
    documentIds: number[]
}

export interface ActivityResponse {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: ActivityDTO[]
}

export interface ActivityTypeDTO {
    id: number,
    name: string,
    activityCategoryId: number
}

export interface CompanyRegistryDTO {
    salesRegisterNumber: number,
    salesRegister: string[],
    purchasesRegisterNumer: number,
    purchasesRegister: string[],
    feesRegisterNumber: number,
    feesRegister: string[],
    registerCharge: boolean,
    registerChargeName: string,
    adminsNumber: number,
    admins: string[],
    membersNumber: number,
    members: string[],
    instrumentalVehicles: boolean,
    instrumentalVehiclesLicensePlate: string,
    notInstrumentalVehicles: boolean,
    notInstrumentalVehiclesLicensePlate: string,
    loansNumber: number,
    fundingNumber: number,
    leasingNumber: number,
    retentionManagement: boolean
}

export interface AcubeAccountDTO {
    name: string,
    iban: string,
    consentExpiresAt: Date,
    uuid: string,
    fiscalId: string
}
export interface CompanyUpdateDTO {
    name?: string,
    surname?: string,
    employeesNumber?: number,
    vatNumber?: string,
    fiscalCode?: string,
    phoneNumber?: string,
    note?: string,
}
export interface AcubeReconnectResponseDTO {
    reconnectUrl: string
}

export interface CompanyUnseenInfoDocumentsDTO {
    companyId: number
    unseenRecived: number
}
export interface CompanyUnseenInfoMessagesDTO {
    topic: number
    unseen: number
}

export interface CompanyEditPremiumDTO {
    premium: boolean
}

export interface RegistryEmailDTO {
    email?: string
}