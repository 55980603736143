import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { findAllAccountantWithoutPagination } from "../accountant/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { findAllFinancialAdvisorWithoutPagination, findFinancialAdvisorsListAvatarsById } from "../financialAdvisor/slice";
import { InfoIcon } from "../icons/info";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { SwitchComponent } from "../switch";
import colors from '../utils/index.module.scss';
import { AccountingSchemeEnum, AnnualForeignInvoicesEnum, AtecosEnum, PeriodicityEnum, SpecialVatSchemeEnum, atecoToNumberMap, foreignToNumberMap, numberToAtecoMap, numberToForeignMap } from "./dto";
import { setCompanyQuotationWizardCreationDTO } from "./slice";

export function ServiceTypeAtecosInvoicesSpecialVat() {
    const companyQuotationWizardState = useAppSelector(state => state.companyQuotation)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const authState = useAppSelector(state => state.auth)
    const accountantState = useAppSelector(state => state.accountant)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (companyQuotationWizardState.openCompanyQuotationWizardModal) {
            if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('accountant')) {
                dispatch(findAllFinancialAdvisorWithoutPagination(keycloak.hasRealmRole('accountant') ? authState.findMeResponse?.id.toString() : undefined))
                dispatch(findAllAccountantWithoutPagination())
            }
            if (keycloak.hasRealmRole('financial-advisor')) {
                const newCompany = [...companyQuotationWizardState.companiesToAdd]

                newCompany[companyQuotationWizardState.index] = {
                    ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                    accountantId: authState.findMeResponse?.accountantId!
                };

                dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
            }
        }
    }, [companyQuotationWizardState.openCompanyQuotationWizardModal])

    useEffect(() => {
        if (
            (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('accountant')) &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0
        ) {
            dispatch(findFinancialAdvisorsListAvatarsById(financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.map(financial => financial.avatarObjectId)))
        }
    }, [financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus])

    if (
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading' ||
        financialAdvisorState.findFinancialAdvisorsListAvatarsByIdStatus === 'loading' ||
        accountantState.findAllAccountantsWithoutPaginationStatus === 'loading'
    ) {
        return (<div style={{ display: 'flex', justifyContent: 'center' }}><SpinnerComponent size={"small"} /></div>)
    }

    if (
        (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('accountant')) && (
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed' ||
            financialAdvisorState.findFinancialAdvisorsListAvatarsByIdStatus === 'failed' ||
            accountantState.findAllAccountantsWithoutPaginationStatus === 'failed'
        )
    ) {
        return (<></>)
    }

    let financialAdvisorList: ReactNode[] = []

    if (
        financialAdvisorState.findFinancialAdvisorsListAvatarsByIdStatus === 'successfully' &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0 &&
        financialAdvisorState.findFinancialAdvisorsListAvatarsByIdResponse !== undefined &&
        financialAdvisorState.findFinancialAdvisorsListAvatarsByIdResponse.length > 0
    ) {
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.forEach((financialAdvisor, index) => {
            financialAdvisorList.push(
                <MenuItem value={financialAdvisor.id} key={'financial-advisor-wizard-' + financialAdvisor.id}>
                    <AvatarComponent
                        type={AvatarType.USER}
                        size={AvatarSize.XS}
                        name={financialAdvisor.name + ' ' + financialAdvisor.surname}
                        src={financialAdvisorState.findFinancialAdvisorsListAvatarsByIdResponse[index]}
                    />
                </MenuItem>
            )
        })
    }

    return (
        <div className="d-flex flex-column w-100 " style={{ gap: 18 }}>
            <SelectComponent
                id={"company-quotation-wizard-periodicity"}
                label="Periodicità"
                value={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].periodicity}
                onChange={(e) => {
                    const newCompany = [...companyQuotationWizardState.companiesToAdd]

                    newCompany[companyQuotationWizardState.index] = {
                        ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                        periodicity: e.target.value as PeriodicityEnum
                    };

                    dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                }}
                menuItems={[
                    <MenuItem key={PeriodicityEnum.Monthly} value={PeriodicityEnum.Monthly}>Mensile</MenuItem>,
                    <MenuItem key={PeriodicityEnum.Quarterly} value={PeriodicityEnum.Quarterly}>Trimestrale</MenuItem>,
                ]}
            />

            <SelectComponent
                id={"company-quotation-wizard-atecos"}
                label="Numero Sezionali/Registri"
                value={numberToAtecoMap(companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].atecoCodesNumber)}
                onChange={(e) => {
                    const atecoValue = atecoToNumberMap.get(e.target.value as AtecosEnum) !== undefined ? atecoToNumberMap.get(e.target.value as AtecosEnum) : 0
                    const newCompany = [...companyQuotationWizardState.companiesToAdd]

                    newCompany[companyQuotationWizardState.index] = {
                        ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                        atecoCodesNumber: atecoValue !== undefined ? atecoValue : 0
                    };

                    dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                }
                }
                menuItems={[
                    <MenuItem key={AtecosEnum.ATECOS_0} value={AtecosEnum.ATECOS_0}>Fino a 2</MenuItem>,
                    <MenuItem key={AtecosEnum.ATECOS_1} value={AtecosEnum.ATECOS_1}>Da 3 a 4</MenuItem>,
                    <MenuItem key={AtecosEnum.ATECOS_2} value={AtecosEnum.ATECOS_2}>Più di 4</MenuItem>
                ]}
            />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 12 }}>
                <SelectComponent
                    tooltipIcon={<InfoIcon colorBase={colors.primary500} />}
                    tooltipLabel="Include fatture passive estere"
                    id={"company-quotation-wizard-banks"}
                    label="Numero fatture cartacee"
                    value={numberToForeignMap(companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].annualForeignInvoices)}
                    onChange={(e) => {
                        const foreignValue = foreignToNumberMap.get(e.target.value as AnnualForeignInvoicesEnum) !== undefined ? foreignToNumberMap.get(e.target.value as AnnualForeignInvoicesEnum) : 0
                        const newCompany = [...companyQuotationWizardState.companiesToAdd]

                        newCompany[companyQuotationWizardState.index] = {
                            ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                            annualForeignInvoices: foreignValue !== undefined ? foreignValue : 0
                        };

                        dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                    }
                    }
                    menuItems={[
                        <MenuItem key={AnnualForeignInvoicesEnum.FOREIGN_EMPTY} value={AnnualForeignInvoicesEnum.FOREIGN_EMPTY}>Nessuna</MenuItem>,
                        <MenuItem key={AnnualForeignInvoicesEnum.FOREIGN_0} value={AnnualForeignInvoicesEnum.FOREIGN_0}>Fino a 10</MenuItem>,
                        <MenuItem key={AnnualForeignInvoicesEnum.FOREIGN_1} value={AnnualForeignInvoicesEnum.FOREIGN_1}>Da 10 a 100</MenuItem>,
                        <MenuItem key={AnnualForeignInvoicesEnum.FOREIGN_2} value={AnnualForeignInvoicesEnum.FOREIGN_2}>Oltre 100</MenuItem>
                    ]}
                />
            </div>
            {
                companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Simplified &&
                <SwitchComponent
                    id="company-quotation-wizard-professionist-with-fund"
                    checked={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].professionistWithFund}
                    label="Professionista per cassa"
                    onChange={() => {
                        const newCompany = [...companyQuotationWizardState.companiesToAdd]

                        newCompany[companyQuotationWizardState.index] = {
                            ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                            professionistWithFund: !companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].professionistWithFund
                        };

                        dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                    }
                    }
                />
            }
            <SwitchComponent
                id="company-quotation-wizard-special-vat-scheme"
                checked={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].specialVatSchemes}
                label="Regimi IVA speciali"
                onChange={() => {
                    const newCompany = [...companyQuotationWizardState.companiesToAdd]

                    newCompany[companyQuotationWizardState.index] = {
                        ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                        specialVatSchemes: !companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].specialVatSchemes,
                        specialVatScheme: companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].specialVatSchemes === false ? null : companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].specialVatScheme
                    };

                    dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                }
                }
            />
            {
                companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].specialVatSchemes &&
                <SelectComponent
                    id={"company-quotation-wizard-special-vat-scheme-list"}
                    //@ts-ignore
                    value={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].specialVatScheme !== null ? companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].specialVatScheme : ''}
                    onChange={(e) => {
                        const newCompany = [...companyQuotationWizardState.companiesToAdd]

                        newCompany[companyQuotationWizardState.index] = {
                            ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                            specialVatScheme: e.target.value === '' ? null : e.target.value as SpecialVatSchemeEnum
                        };

                        dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                    }
                    }
                    menuItems={[
                        <MenuItem key={'no-special-vat-scheme'} value={''}>Seleziona regime IVA</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_1} value={SpecialVatSchemeEnum.SCHEME_1}>Agricoltura e pesca</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_2} value={SpecialVatSchemeEnum.SCHEME_2}>Vendita sali e tabacchi</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_3} value={SpecialVatSchemeEnum.SCHEME_3}>Commercio dei fiammiferi</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_4} value={SpecialVatSchemeEnum.SCHEME_4}>Editoria</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_5} value={SpecialVatSchemeEnum.SCHEME_5}>Gestione di servizi di telefonia pubblica</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_6} value={SpecialVatSchemeEnum.SCHEME_6}>Rivendita di documenti di trasporto pubblico e di sosta</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_7} value={SpecialVatSchemeEnum.SCHEME_7}>Intrattenimenti, giochi e altre attività di cui al D.P.R. n. 640/1972</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_8} value={SpecialVatSchemeEnum.SCHEME_8}>Agenzie di viaggi e turismo</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_9} value={SpecialVatSchemeEnum.SCHEME_9}>Agriturismo</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_10} value={SpecialVatSchemeEnum.SCHEME_10}>Vendite a domicilio</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_11} value={SpecialVatSchemeEnum.SCHEME_11}>Rivendita di beni usati, di oggetti d'arte, d'antiquariato o da collezione</MenuItem>,
                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_12} value={SpecialVatSchemeEnum.SCHEME_12}>Agenzie di vendite all'asta di oggetti d'arte, antiquariato o da collezione</MenuItem>,
                    ]}
                />
            }
            {
                companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Simplified &&
                <SelectComponent
                    id={"company-quotation-wizard-iva-month"}
                    label="Mese di partenza IVA"
                    value={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].ivaStartMonth.toString()}
                    onChange={(e) => {
                        const newCompany = [...companyQuotationWizardState.companiesToAdd]

                        newCompany[companyQuotationWizardState.index] = {
                            ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                            ivaStartMonth: e.target.value
                        };

                        dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                    }
                    }
                    menuItems={
                        [
                            <MenuItem value='' key={'select-a-month'}>Seleziona mese</MenuItem>,
                            <MenuItem value='0' key={'0'}>Gennaio</MenuItem>,
                            <MenuItem value='1' key={'1'}>Febbraio</MenuItem>,
                            <MenuItem value='2' key={'2'}>Marzo</MenuItem>,
                            <MenuItem value='3' key={'3'}>Aprile</MenuItem>,
                            <MenuItem value='4' key={'4'}>Maggio</MenuItem>,
                            <MenuItem value='5' key={'5'}>Giugno</MenuItem>,
                            <MenuItem value='6' key={'6'}>Luglio</MenuItem>,
                            <MenuItem value='7' key={'7'}>Agosto</MenuItem>,
                            <MenuItem value='8' key={'8'}>Settembre</MenuItem>,
                            <MenuItem value='9' key={'9'}>Ottobre</MenuItem>,
                            <MenuItem value='10' key={'10'}>Novembre</MenuItem>,
                            <MenuItem value='11' key={'11'}>Dicembre</MenuItem>,
                        ]
                    }
                />
            }
            {
                (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('accountant')) && (
                    <SelectComponent
                        required
                        label="Commercialista"
                        id={"company-quotation-wizard-special-financial-advisor"}
                        //@ts-ignore
                        value={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].financialAdvisorId !== undefined ? companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].financialAdvisorId : 0}
                        onChange={(e) => {
                            const newCompany = [...companyQuotationWizardState.companiesToAdd]

                            newCompany[companyQuotationWizardState.index] = {
                                ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                                financialAdvisorId: e.target.value as unknown as number,
                                accountantId: Number(accountantState.findAllAccountantsWithoutPaginationResponse?.data.find(account => account.financialAdvisorId === e.target.value)?.id)
                            };

                            dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                        }
                        }
                        menuItems={[
                            <MenuItem key={'no-special-vat-scheme'} value={0}>Seleziona commercialista</MenuItem>,
                            financialAdvisorList
                        ]}
                    />
                )
            }
        </div>
    )
}