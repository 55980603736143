import { Accordion, AccordionDetails, AccordionSummary, MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { findAllActivityCategory } from "../activity/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { AccountingSchemeEnum, AnnualForeignInvoicesEnum, AtecosEnum, BankEnum, JournalEnum, PeriodicityEnum, SpecialVatSchemeEnum, atecoToNumberMap, bankToNumberMap, foreignToNumberMap, journalToNumberMap, numberToAtecoMap, numberToBankMap, numberToForeignMap, numberToJournalMap, specialVatSchemeMap } from "../companyQuotationWizard/dto";
import { atecoLabelMap, banksLabelMap, foreignLabelMap, journalLabelMap } from "../companyQuotationWizard/summary";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ErrorPopup } from "../errorPopup";
import { FileInputComponent } from "../fileInput";
import { FormGroup } from "../formGroup";
import { HeadingComponent } from "../heading";
import { HeadingSize } from "../heading/dto";
import { AlertTriangleIcon } from "../icons/alertTriangle";
import { ChevronDownIcon } from "../icons/chevronDown";
import { CloseIcon } from "../icons/close";
import { InfoIcon } from "../icons/info";
import { keycloak } from "../keycloak";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { UpdateApprovationEnum, UpdateCompanySettingsDTO } from "../requestToConfirm/dto";
import { findAllRequestToConfirm, setResetUpdateCompanySettingsRequest, setUpdateCompanySettingAccountingScheme, setUpdateCompanySettingActivityCategoryIds, setUpdateCompanySettingAnnualForeignInvoices, setUpdateCompanySettingAtecoCodesNumber, setUpdateCompanySettingBanksNumber, setUpdateCompanySettingCompanyId, setUpdateCompanySettingJournal, setUpdateCompanySettingSpecialVatScheme, setUpdateCompanySettingSpecialVatSchemes } from "../requestToConfirm/slice";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { SuccessPopup } from "../successPopup";
import { SwitchComponent } from "../switch";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";
import colors from '../utils/index.module.scss';
import { AtecoModal } from "./atecoModal";
import { BankModal } from "./bankModal";
import { AccountingSchemeMap, CompanyStatusEnum } from "./dto";
import { editBankAccounts, findAllAtecos, resetErrorRegistryEmail, resetErrorUpdateRegistryEmail, resetErrorUpdateRegistryVat, setCompanyEditPremium, setCompanyUpdateRegistryBusinessName, setCompanyUpdateRegistryEmail, setCompanyUpdateRegistryEmployeesNumber, setCompanyUpdateRegistryFiscalCode, setCompanyUpdateRegistryName, setCompanyUpdateRegistryNote, setCompanyUpdateRegistryPhoneNumber, setCompanyUpdateRegistryStatus, setCompanyUpdateRegistrySurname, setCompanyUpdateRegistryVatNumber, setEditBankStatus, setFindCompanyAvatarStatus, setOpenAtecoModal, setOpenBankModal, setOpenDeleteBankModal, setRegistryEmail, setRegistryEmailStatus, setSaveAtecosStatus, setUpdateCompanyEmployeesNumber, setUpdateCompanyFiscalCode, setUpdateCompanyName, setUpdateCompanyNote, setUpdateCompanyPhoneNumber, setUpdateCompanyStatus, setUpdateCompanySurname, setUpdateCompanyVatNumber, setUpdateRegistryTabValue } from "./slice";

interface Props {
    setFile: React.Dispatch<React.SetStateAction<File | null>>
}

const atecoLabels = (atecos: number): string => {
    if (atecos <= 2) {
        return ('Inseriscine fino a 2')
    } else if (atecos > 2 && atecos <= 4) {
        return ('Inseriscine da 3 a 4')
    } else {
        return ('Inseriscine più di 4')
    }
}

const banksLabel = (bank: number): string => {
    if (bank <= 2) {
        return ('Inseriscine fino a 2')
    } else if (bank > 2 && bank <= 5) {
        return ('Inseriscine da 2 a 5')
    } else {
        return ('Inseriscine più di 5')
    }
}
export function CompanyProfileComponent(props: Props) {
    const { setFile } = props

    const companyState = useAppSelector(state => state.company)
    const activityState = useAppSelector(state => state.activity)
    const requestToConfirmState = useAppSelector(state => state.requestToConfirm)
    const objectState = useAppSelector(state => state.object)
    const authState = useAppSelector(state => state.auth)

    const [avatarToBase64, setAvatarToBase64] = useState<string>('')
    const [bankIndexToDelete, setBankIndexToDelete] = useState<number>(-1)
    const [showEmail, setShowEmail] = useState<boolean>(companyState.findCompanyResponse?.email !== null)

    const dispatch = useAppDispatch()

    let company = companyState.findCompanyResponse
    let requestToConfirm: UpdateCompanySettingsDTO | undefined

    useEffect(() => {
        if (
            companyState.findCompanyStatus === 'successfully' &&
            companyState.settingsTabValue === 0
        ) {
            dispatch(findAllActivityCategory())
            dispatch(findAllAtecos({ page: 0, itemsPerPage: 10 }))
            dispatch(findAllRequestToConfirm(requestToConfirmState.requestToAddFilters))
            dispatch(setResetUpdateCompanySettingsRequest(requestToConfirmState.updateCompanySettingsCreationRequest))
            dispatch(setUpdateCompanySettingCompanyId(company?.id))
            dispatch(setUpdateCompanySettingActivityCategoryIds(company?.activityCategoryIds ? [...company?.activityCategoryIds] : []))
        }
    }, [companyState.findCompanyStatus, companyState.settingsTabValue])

    useEffect(() => {
        if (
            companyState.findCompanyAvatarStatus === 'successfully' &&
            companyState.settingsTabValue === 0
        ) {
            dispatch(setFindCompanyAvatarStatus('idle'))
            if (companyState.companyAvatar !== undefined && companyState.companyAvatar !== null) {
                setAvatarToBase64(companyState.companyAvatar)
            }
        }
    }, [companyState.findCompanyAvatarStatus])

    useEffect(() => {
        if (companyState.companyUpdateDTO.phoneNumber === '')
            dispatch(setUpdateCompanyPhoneNumber(undefined))
        if (companyState.companyUpdateDTO.name === '')
            dispatch(setUpdateCompanyName(undefined))
        if (companyState.companyUpdateDTO.surname === '')
            dispatch(setUpdateCompanySurname(undefined))
        if (companyState.companyUpdateDTO.employeesNumber?.toString() === '')
            dispatch(setUpdateCompanyEmployeesNumber(undefined))
        if (companyState.companyUpdateDTO.fiscalCode === '')
            dispatch(setUpdateCompanyFiscalCode(undefined))
        if (companyState.companyUpdateDTO.note === '')
            dispatch(setUpdateCompanyNote(undefined))
    }, [companyState.companyUpdateDTO])

    useEffect(() => {
        if (companyState.companyUpdateRegistryRequest.businessName === '')
            dispatch(setCompanyUpdateRegistryBusinessName(undefined))
        if (companyState.companyUpdateRegistryRequest.email === '')
            dispatch(setRegistryEmail(undefined))
        if (companyState.companyUpdateRegistryRequest.employeesNumber?.toString() === '')
            dispatch(setCompanyUpdateRegistryEmployeesNumber(undefined))
        if (companyState.companyUpdateRegistryRequest.fiscalCode === '')
            dispatch(setCompanyUpdateRegistryFiscalCode(undefined))
        if (companyState.companyUpdateRegistryRequest.name === '')
            dispatch(setCompanyUpdateRegistryName(undefined))
        if (companyState.companyUpdateRegistryRequest.note === '')
            dispatch(setCompanyUpdateRegistryNote(undefined))
        if (companyState.companyUpdateRegistryRequest.phoneNumber === '')
            dispatch(setCompanyUpdateRegistryPhoneNumber(undefined))
        if (companyState.companyUpdateRegistryRequest.surname === '')
            dispatch(setCompanyUpdateRegistrySurname(undefined))
        if (companyState.companyUpdateRegistryRequest.vatNumber === '')
            dispatch(setCompanyUpdateRegistryVatNumber(undefined))
    }, [companyState.companyUpdateRegistryRequest])

    useEffect(() => {
        if (requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === AccountingSchemeEnum.Ordinary) {
            dispatch(setUpdateCompanySettingJournal(2500))
            dispatch(setUpdateCompanySettingBanksNumber(bankToNumberMap.get(BankEnum.BANK_0)))
            dispatch(setUpdateCompanySettingActivityCategoryIds(company?.activityCategoryIds!))
        } else if (requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === AccountingSchemeEnum.Simplified) {
            dispatch(setUpdateCompanySettingJournal(null))
            dispatch(setUpdateCompanySettingBanksNumber(null))
            dispatch(setUpdateCompanySettingActivityCategoryIds([1]))
        } else if (requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === null) {
            dispatch(setUpdateCompanySettingJournal(null))
            dispatch(setUpdateCompanySettingBanksNumber(null))
            dispatch(setUpdateCompanySettingActivityCategoryIds(company?.activityCategoryIds ? [...company?.activityCategoryIds] : []))
        }
    }, [requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme])

    if (
        companyState.findCompanyStatus === 'loading' ||
        companyState.saveRolesStatus === 'loading' ||
        companyState.editBankStatus === 'loading' ||
        companyState.editCompanyActivityTypesStatus === 'loading' ||
        companyState.editTodosStatus === 'loading' ||
        companyState.setActivityOpenStatus === 'loading' ||
        companyState.setActivityCompleteStatus === 'loading' ||
        objectState.saveDocumentStatus === 'loading' ||
        companyState.deleteDocumentStatus === 'loading' ||
        activityState.findAllActivityCategoryStatus === 'loading' ||
        companyState.findCompanyAvatarStatus === 'loading' ||
        companyState.findLoansStatus === 'loading' ||
        companyState.saveAtecosStatus === 'loading' ||
        companyState.findFundingsStatus === 'loading' ||
        companyState.companyUpdateStatus === 'loading' ||
        companyState.companyUpdateRegistryStatus === 'loading' ||
        companyState.findLeasingsStatus === 'loading' ||
        companyState.findAtecoByIdStatus === 'loading' ||
        requestToConfirmState.findAllUpdateCompanySettingsStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    if (
        companyState.findCompanyStatus === 'failed' ||
        activityState.findAllActivityCategoryStatus === 'failed'
    ) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    if (requestToConfirmState.findAllUpdateCompanySettingsStatus === "successfully" &&
        companyState.findCompanyStatus === 'successfully'
    ) {
        requestToConfirm = requestToConfirmState.findAllUpdateCompanySettingsResponse?.data.find(id => id.companyId.toString() === companyState.findCompanyResponse?.id.toString())
    }

    let activityCategories: ReactNode[] = []
    let bankAccounts: ReactNode[] = []
    let atecoList: ReactNode[] = []

    if (
        companyState.findAtecoByIdStatus === 'successfully' &&
        companyState.findAtecoByIdResponse.length > 0
    ) {
        companyState.findAtecoByIdResponse.forEach(ateco => {
            atecoList.push(
                <PillComponent
                    key={ateco.id}
                    label={ateco.code}
                    size={PillsSize.MD}
                    color={PillsColor.PRIMARY}
                    emphasis={PillsEmphasis.LOW}
                    outline={PillsOutline.TRUE}
                />
            )
        })
    }

    if (companyState.findCompanyStatus === 'successfully' && company !== undefined && companyState.settingsTabValue === 0) {
        if (company.bankingAccounts !== null)
            company.bankingAccounts.forEach((bank, index) => {
                bankAccounts.push(
                    <div key={bank} className="neutral-container--padding-small flex-row justify-content-between align-items-center" >
                        <span className="typography-neutral--400 text text--lg typography--semibold">
                            {bank.split('::')[0] + ' '}
                            <span className="typography--medium">
                                {'→ ' + bank.split('::')[1]}
                            </span>
                        </span>
                        {
                            (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor')) &&
                            <ButtonComponent
                                label={""}
                                disabled={(!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor')) || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                icon={<CloseIcon colorBase={""} />}
                                onClick={() => {
                                    setBankIndexToDelete(index)
                                    dispatch(setOpenDeleteBankModal(true))
                                }}
                                color={Colors.NEUTRAL}
                                variant={Variant.LINK}
                                size={Size.SM}
                                iconStyle={IconStyle.ONLY}
                            />
                        }
                    </div>
                )
            })
    }

    if (
        activityState.findAllActivityCategoryResponse !== undefined &&
        activityState.findAllActivityCategoryResponse.data !== undefined &&
        activityState.findAllActivityCategoryResponse.data.length > 0 &&
        companyState.settingsTabValue === 0
    ) {
        activityState.findAllActivityCategoryResponse.data.forEach(activity => {
            activityCategories.push(
                <SwitchComponent
                    label={activity.name}
                    id={'company-settings-activity-category-' + activity.id.toString()}
                    key={'company-settings-activity-category-' + activity.id}
                    checked={requestToConfirmState.updateCompanySettingsCreationRequest.activityCategoryIds.includes(activity.id)}
                    onChange={(e, checked) => {
                        if (checked) {
                            dispatch(setUpdateCompanySettingActivityCategoryIds([...requestToConfirmState.updateCompanySettingsCreationRequest.activityCategoryIds, activity.id]))
                        }
                        else {
                            dispatch(setUpdateCompanySettingActivityCategoryIds([...requestToConfirmState.updateCompanySettingsCreationRequest.activityCategoryIds.filter(act => act !== activity.id)]))
                        }

                    }}
                    disabled={requestToConfirm?.updateStatus === UpdateApprovationEnum.IDLE || requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === AccountingSchemeEnum.Simplified || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id) || company?.state === CompanyStatusEnum.RegistryPending}
                />
            )
        })
    }

    let activityCategoryRequest: string = ''
    if (requestToConfirm?.activityCategoryIds !== null && requestToConfirm?.activityCategoryIds !== undefined && requestToConfirm.activityCategoryIds.length > 0) {
        requestToConfirm.activityCategoryIds.forEach((category, _index) => {
            if (activityState.findAllActivityCategoryResponse !== undefined && activityState.findAllActivityCategoryResponse.data !== undefined && activityState.findAllActivityCategoryResponse.data.length > 0) {
                activityState.findAllActivityCategoryResponse.data.forEach(_category => {
                    if (_category.id === category) {
                        let addString = ''
                        if (_index === 0) {
                            addString = ' '
                        } else if (requestToConfirm?.activityCategoryIds !== null && _index === requestToConfirm?.activityCategoryIds.length! - 1) {
                            addString = ' e '
                        } else {
                            addString = ', '
                        }
                        activityCategoryRequest = activityCategoryRequest + addString + _category.name
                    }
                })
            }
        })
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setUpdateRegistryTabValue(newValue))
    };

    return (
        <div className="row m-0 p-0" style={{ gap: 24 }}>
            <ErrorPopup
                active={companyState.companyUpdateRegistryStatus === 'failed'}
                close={() => dispatch(setCompanyUpdateRegistryStatus('idle'))}
                message="Si è verificato un errore durante la modifica dell'azienda"
            />
            <SuccessPopup
                active={companyState.companyUpdateRegistryStatus === 'successfully'}
                close={() => dispatch(setCompanyUpdateRegistryStatus('idle'))}
                message="Azienda aggiornata"
            />
            <ErrorPopup
                active={companyState.companyUpdateStatus === 'failed'}
                close={() => dispatch(setUpdateCompanyStatus('idle'))}
                message="Si è verificato un errore durante la modifica dell'azienda"
            />
            <SuccessPopup
                active={companyState.companyUpdateStatus === 'successfully'}
                close={() => dispatch(setUpdateCompanyStatus('idle'))}
                message="Azienda aggiornata"
            />
            <ErrorPopup
                active={companyState.saveAtecosStatus === 'failed'}
                close={() => dispatch(setSaveAtecosStatus('idle'))}
                message="Si è verificato un errore durante la modifica della lista dei codici ateco"
            />
            <SuccessPopup
                active={companyState.saveAtecosStatus === 'successfully'}
                close={() => dispatch(setSaveAtecosStatus('idle'))}
                message="Lista codici ateco aggiornata"
            />
            <ErrorPopup
                active={companyState.editBankStatus === 'failed'}
                close={() => dispatch(setEditBankStatus('idle'))}
                message="Si è verificato un errore durante la modifica della lista delle banche"
            />
            <SuccessPopup
                active={companyState.editBankStatus === 'successfully'}
                close={() => dispatch(setEditBankStatus('idle'))}
                message="Lista banche aggiornata"
            />
            <ErrorPopup
                active={companyState.companyRegistryEmailStatus === 'failed'}
                close={() => dispatch(setRegistryEmailStatus('idle'))}
                message="Si è verificato un errore durante l'attivazione del profilo azienda"
            />
            <SuccessPopup
                active={companyState.companyRegistryEmailStatus === 'successfully'}
                close={() => dispatch(setRegistryEmailStatus('idle'))}
                message="Profilo azienda attivato"
            />
            <div className="col-12 col-lg m-0 p-0">
                <div className="row m-0 p-0" style={{ gap: 16, height: '100%', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <div className="col-12 m-0 p-0">
                        <div className="neutral-container--padding-large">
                            <HeadingComponent label={"Anagrafica azienda"} buttons={[]} size={HeadingSize.SM} />
                            <div className="w-100 d-flex gap-2">
                                <AvatarComponent
                                    src={avatarToBase64}
                                    type={AvatarType.SINGLE}
                                    size={AvatarSize.MD}
                                />
                                <FileInputComponent
                                    disabled={(!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant') || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id))}
                                    id={"company-profile-update-registry-avatar"}
                                    onChange={e => {
                                        setFile(e[0]);
                                        toBase64(e[0])
                                            .then(data => {
                                                setAvatarToBase64('data:' + e[0].type + ';base64,' + data)
                                            })
                                    }}
                                />
                            </div>
                            <FormGroup label={"Ragione sociale"} required inputs={[
                                <TextInput
                                    id={"company-profile-update-registry-business-name"}
                                    type={"text"}
                                    disabled={company?.state === CompanyStatusEnum.Active || (!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('financial-advisor'))}
                                    placeholder={"Ragione sociale"}
                                    defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.businessName !== null ? company?.businessName : '') : companyState.companyUpdateRegistryRequest.businessName}
                                    onChange={e => {
                                        dispatch(setCompanyUpdateRegistryBusinessName(e.target.value))
                                    }}
                                />]}
                                style={"column"}
                            />
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Nome"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-name"}
                                                type={"text"}
                                                placeholder={"Nome"}
                                                disabled={(!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant')) || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.name !== null ? company?.name : '') : companyState.companyUpdateRegistryRequest.name}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanyName(e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistryName(e.target.value))
                                                }}
                                            />]}
                                        style={"column"} />
                                </div>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Cognome"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-surname"}
                                                type={"text"}
                                                placeholder={"Cognome"}
                                                disabled={(!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant')) || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.surname !== null ? company?.surname : '') : companyState.companyUpdateRegistryRequest.surname}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanySurname(e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistrySurname(e.target.value))
                                                }}
                                            />]} style={"column"}
                                    />
                                </div>
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        required
                                        label={"Partita IVA"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-vat-number"}
                                                type={"text"}
                                                disabled={company?.state === CompanyStatusEnum.Active || (!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant'))}
                                                placeholder={"Partita IVA"}
                                                onFocus={e => dispatch(resetErrorUpdateRegistryVat())}
                                                infoText={companyState.errors.updateRegistry.vat !== undefined ? 'Questa partita IVA è già in uso' : 'Inserire da 11 a 16 caratteri'}
                                                error={companyState.errors.updateRegistry.vat !== undefined}
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.vatNumber !== null ? company?.vatNumber : '') : companyState.companyUpdateRegistryRequest.vatNumber}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanyVatNumber(e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistryVatNumber(e.target.value))
                                                }}
                                            />]}
                                        style={"column"}
                                    />
                                </div>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Codice Fiscale"}
                                        required
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-fiscal-code"}
                                                type={"text"}
                                                required
                                                infoText="Inserire da 11 a 16 caratteri"
                                                placeholder={"Codice fiscale"}
                                                disabled={(!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant')) || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.fiscalCode !== null ? company?.fiscalCode : '') : companyState.companyUpdateRegistryRequest.fiscalCode}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanyFiscalCode(e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistryFiscalCode(e.target.value))
                                                }}
                                            />]}
                                        style={"column"}
                                    />
                                </div>
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Numero dipendenti"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-employees-number"}
                                                type={"number"}
                                                disabled={(!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant') || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id))}
                                                placeholder={"Numero dipendenti"}
                                                defaultValue={company?.employeesNumber !== null ? company?.employeesNumber : ''}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanyEmployeesNumber(e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistryEmployeesNumber(e.target.value))
                                                }}
                                            />]}
                                        style={"column"}
                                    />
                                </div>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Telefono"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-phone-number"}
                                                type={"text"}
                                                placeholder={"Telefono"}
                                                disabled={(!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant')) || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                                infoText="Inserire da 9 a 10 caratteri"
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.phoneNumber !== null ? company?.phoneNumber : '') : companyState.companyUpdateRegistryRequest.phoneNumber}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanyPhoneNumber(e.target.value === '' ? null : e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistryPhoneNumber(e.target.value === '' ? null : e.target.value))
                                                }}
                                            />]}
                                        style={"column"}
                                    />
                                </div>
                                {
                                    company?.state === CompanyStatusEnum.RegistryPending &&
                                    <FormGroup
                                        label={"Email"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-email"}
                                                type={"text"}
                                                disabled={(!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant')) || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                                placeholder={"Email"}
                                                onFocus={e => {
                                                    dispatch(resetErrorUpdateRegistryEmail())
                                                    dispatch(resetErrorRegistryEmail())
                                                }}
                                                infoText={
                                                    (companyState.errors.updateRegistry.email !== undefined || companyState.errors.registryEmail.email !== undefined) ? 'Questo indirizzo email è già in uso' : undefined}
                                                error={companyState.errors.updateRegistry.email !== undefined || companyState.errors.registryEmail.email !== undefined}
                                                defaultValue={companyState.companyUpdateRegistryRequest.email}
                                                onChange={e => {
                                                    dispatch(setCompanyUpdateRegistryEmail(e.target.value === '' ? null : e.target.value))
                                                }}
                                            />
                                        ]}
                                        style={"column"}
                                    />
                                }
                                <FormGroup
                                    label={"Note"}
                                    inputs={[
                                        <TextInput
                                            id={"company-profile-update-registry-notes"}
                                            type={"number"}
                                            placeholder={"Un po' d’informazioni sull'azienda e sui servizi che offre."}
                                            multiline
                                            disabled={(!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant')) || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                            infoText="275 caratteri rimasti"
                                            defaultValue={company?.note !== null ? company?.note : ''}
                                            onChange={e => {
                                                if (company?.state === CompanyStatusEnum.Active) {
                                                    dispatch(setUpdateCompanyNote(e.target.value))
                                                } else
                                                    dispatch(setCompanyUpdateRegistryNote(e.target.value))
                                            }}
                                        />
                                    ]}
                                    style={"column"}
                                />
                            </div>

                        </div>
                    </div>
                    {
                        company?.state === CompanyStatusEnum.Active &&
                        <div className="col-12 m-0 p-0">
                            <div className="neutral-container--padding-large" style={{ minHeight: '90px', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="row m-0 p-0 w-100 gap-4 align-items-center">
                                    <div className="col-12 col-lg-auto p-0 m-0">
                                        <SwitchComponent
                                            label="Attiva profilo azienda"
                                            disabled={company?.email !== null || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                            checked={showEmail}
                                            onChange={() => setShowEmail(!showEmail)}
                                        />
                                    </div>
                                    {
                                        showEmail &&
                                        <div className="col-12 col-lg p-0 m-0">
                                            <TextInput
                                                id={"company-profile-update-registry-email"}
                                                type={"text"}
                                                disabled={company?.email !== null || (!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant')) || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                                placeholder={"Email"}
                                                onFocus={e => {
                                                    dispatch(resetErrorUpdateRegistryEmail())
                                                    dispatch(resetErrorRegistryEmail())
                                                }}
                                                infoText={
                                                    (companyState.errors.updateRegistry.email !== undefined || companyState.errors.registryEmail.email !== undefined) ? 'Questo indirizzo email è già in uso' : undefined}
                                                error={companyState.errors.updateRegistry.email !== undefined || companyState.errors.registryEmail.email !== undefined}
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.email !== null ? company?.email : '') : companyState.companyRegistryEmailRequest.email}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active)
                                                        dispatch(setRegistryEmail(e.target.value === '' ? null : e.target.value))
                                                    else
                                                        dispatch(setCompanyUpdateRegistryEmail(e.target.value))
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="col-12 col-lg m-0 p-0">
                <div className="row m-0 p-0" style={{ gap: 16, height: '100%', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <div className="col-12 m-0 p-0">
                        <div className="neutral-container--padding-large">
                            <HeadingComponent label={"Impostazioni"} size={HeadingSize.SM} buttons={[]} />
                            {
                                requestToConfirm !== undefined &&
                                <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                    <div className="col-12 col-lg m-0 p-0">
                                        <Accordion style={{ border: "1px solid", borderRadius: "8px", backgroundColor: colors.warning50, borderColor: colors.warning400, boxShadow: "none" }}>
                                            <AccordionSummary
                                                expandIcon={<ChevronDownIcon colorBase={colors.warning800} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <div style={{ color: colors.warning800, display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                                    <AlertTriangleIcon colorBase={colors.warning800} />
                                                    <span className="text text--lg typography--semibold">
                                                        Modifiche in attesa di approvazione
                                                    </span>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails key={"request-to-confirm " + requestToConfirm?.id}>
                                                <ul style={{ listStyleType: "initial", paddingLeft: "20px" }}>
                                                    {
                                                        requestToConfirm?.accountingScheme !== null && (
                                                            <li style={{ color: colors.warning600 }}>
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4 }}>
                                                                    <span className="m-0 p-0 text text--lg typography--regular" style={{ color: colors.warning600 }}>
                                                                        {"Contabilità "}
                                                                    </span>
                                                                    <span
                                                                        className="text text--lg typography--semibold"
                                                                        style={{ color: colors.warning600 }}
                                                                    >
                                                                        {AccountingSchemeMap.get(
                                                                            requestToConfirm?.accountingScheme as AccountingSchemeEnum
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    {
                                                        requestToConfirm?.journal !== null && (
                                                            <li style={{ color: colors.warning600 }}>
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4 }}>
                                                                    <span className="text text--lg typography--semibold" style={{ color: colors.warning600 }}>
                                                                        {"Movimento libro giornale:"}
                                                                    </span>
                                                                    {journalLabelMap.get(numberToJournalMap(requestToConfirm?.journal!))}
                                                                </div>

                                                            </li>
                                                        )
                                                    }
                                                    {
                                                        requestToConfirm?.annualForeignInvoices !== null && (
                                                            <li style={{ color: colors.warning600 }} >
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4 }}>
                                                                    <span className="text text--lg typography--semibold" style={{ color: colors.warning600 }}>
                                                                        {"Fatture esterne annue:"}
                                                                    </span>
                                                                    {foreignLabelMap.get(numberToForeignMap(requestToConfirm?.annualForeignInvoices!))}
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    {
                                                        requestToConfirm?.atecoCodesNumber !== null && (
                                                            <li style={{ color: colors.warning600 }} >
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4 }}>
                                                                    <span className="text text--lg typography--semibold" style={{ color: colors.warning600 }}>
                                                                        {"Numero Sezionali/Registri:"}
                                                                    </span>
                                                                    {atecoLabelMap.get(numberToAtecoMap(requestToConfirm?.atecoCodesNumber!))}
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    {
                                                        requestToConfirm?.banksNumber !== null && (
                                                            <li style={{ color: colors.warning600 }} >
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4 }}>
                                                                    <span className="text text--lg typography--semibold" style={{ color: colors.warning600 }}>
                                                                        {"Numero estratti conto:"}
                                                                    </span>
                                                                    {banksLabelMap.get(numberToBankMap(requestToConfirm?.banksNumber!))}
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    {
                                                        (requestToConfirm?.specialVatSchemes !== null || requestToConfirm?.specialVatScheme !== null) && (
                                                            <li style={{ color: colors.warning600 }} >
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4, width: "100%" }}>
                                                                    <span className="text text--lg typography--semibold" style={{ color: colors.warning600 }}>
                                                                        {"Regimi IVA speciali "}
                                                                    </span>
                                                                    {
                                                                        requestToConfirm.specialVatSchemes &&
                                                                        <span className="text text--lg typography--semibold" style={{ color: colors.warning600 }}>
                                                                            {"-> " + (specialVatSchemeMap.get(requestToConfirm.specialVatScheme)?.length! > 60 ? specialVatSchemeMap.get(requestToConfirm.specialVatScheme)?.substring(0, 60).concat("...") : specialVatSchemeMap.get(requestToConfirm.specialVatScheme))}
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    {
                                                        requestToConfirm?.activityCategoryIds.length! > 0 && (
                                                            <li style={{ color: colors.warning600 }} >
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4 }}>
                                                                    <span className="text text--lg typography--semibold" style={{ color: colors.warning600 }}>
                                                                        {'Gestione servizi ' + activityCategoryRequest}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                            }
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <SelectComponent
                                        id={"company-profile-regime"}
                                        value={requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === null ? company?.accountingScheme : requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme}
                                        menuItems={[
                                            <MenuItem key='accounting-scheme-0' value=''>Regime contabile</MenuItem>,
                                            <MenuItem key={AccountingSchemeEnum.Ordinary} value={AccountingSchemeEnum.Ordinary}>{AccountingSchemeMap.get(AccountingSchemeEnum.Ordinary)}</MenuItem>,
                                            <MenuItem key={AccountingSchemeEnum.Simplified} value={AccountingSchemeEnum.Simplified}>{AccountingSchemeMap.get(AccountingSchemeEnum.Simplified)}</MenuItem>,
                                        ]}
                                        label="Regime contabile"
                                        onChange={(e) => dispatch(setUpdateCompanySettingAccountingScheme(e.target.value === company?.accountingScheme ? null : e.target.value))}
                                        disabled={requestToConfirm?.updateStatus === UpdateApprovationEnum.IDLE ||
                                            company?.professionistWithFund ||
                                            (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id) ||
                                            company?.state === CompanyStatusEnum.RegistryPending
                                        }
                                    />
                                </div>
                                <div className="col-12 col-lg m-0 p-0">
                                    <SelectComponent
                                        disabled
                                        id={"company-profile-periodicity"}
                                        value={company?.periodicity}
                                        menuItems={[
                                            <MenuItem key='periodicity-0' value=''>Seleziona la tipologia...</MenuItem>,
                                            <MenuItem key={'periodicity-' + company?.periodicity} value={company?.periodicity}>{company?.periodicity === PeriodicityEnum.Monthly ? 'Mensile' : 'Trimestrale'}</MenuItem>
                                        ]}
                                        label="Periodicità operazioni"
                                    />
                                </div>
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                {
                                    (company?.journal !== null || requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === AccountingSchemeEnum.Ordinary) &&
                                    <div className="col-12 col-lg m-0 p-0">
                                        <SelectComponent
                                            id={"company-profile-journal"}
                                            value={requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === AccountingSchemeEnum.Simplified ? "" : requestToConfirmState.updateCompanySettingsCreationRequest.journal !== null ? numberToJournalMap(requestToConfirmState.updateCompanySettingsCreationRequest.journal) : numberToJournalMap(company?.journal!)}
                                            menuItems={[
                                                <MenuItem key='journal-0' value=''>Seleziona la tipologia...</MenuItem>,
                                                <MenuItem key={JournalEnum.JOURNAL_0} value={JournalEnum.JOURNAL_0}>Fino a 5.000</MenuItem>,
                                                <MenuItem key={JournalEnum.JOURNAL_1} value={JournalEnum.JOURNAL_1}>Da 5.000 a 10.000</MenuItem>,
                                                <MenuItem key={JournalEnum.JOURNAL_2} value={JournalEnum.JOURNAL_2}>Da 10.000 a 15.000</MenuItem>,
                                                <MenuItem key={JournalEnum.JOURNAL_3} value={JournalEnum.JOURNAL_3}>Da 15.000 a 20.000</MenuItem>,
                                                <MenuItem key={JournalEnum.JOURNAL_4} value={JournalEnum.JOURNAL_4}>Da 20.000 a 30.000</MenuItem>,
                                                <MenuItem key={JournalEnum.JOURNAL_5} value={JournalEnum.JOURNAL_5}>Da 30.000 a 50.000</MenuItem>,
                                                <MenuItem key={JournalEnum.JOURNAL_6} value={JournalEnum.JOURNAL_6}>Oltre 50.000</MenuItem>
                                            ]}
                                            label="Movimenti libro giornale"
                                            onChange={(e) => {
                                                dispatch(setUpdateCompanySettingJournal(journalToNumberMap.get(e.target.value as JournalEnum) === company?.journal ? null : journalToNumberMap.get(e.target.value as JournalEnum)))
                                            }}
                                            disabled={requestToConfirm?.updateStatus === UpdateApprovationEnum.IDLE ||
                                                requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === AccountingSchemeEnum.Simplified ||
                                                (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id) ||
                                                company?.state === CompanyStatusEnum.RegistryPending
                                            }
                                        />
                                    </div>
                                }
                                <div className="col-12 col-lg m-0 p-0">
                                    <SelectComponent
                                        id={"company-profile-foreign"}
                                        value={requestToConfirmState.updateCompanySettingsCreationRequest.annualForeignInvoices === null ? numberToForeignMap(company?.annualForeignInvoices!) : numberToForeignMap(requestToConfirmState.updateCompanySettingsCreationRequest.annualForeignInvoices!)}
                                        menuItems={[
                                            <MenuItem key={AnnualForeignInvoicesEnum.FOREIGN_EMPTY} value={AnnualForeignInvoicesEnum.FOREIGN_EMPTY}>Nessuna</MenuItem>,
                                            <MenuItem key={AnnualForeignInvoicesEnum.FOREIGN_0} value={AnnualForeignInvoicesEnum.FOREIGN_0}>Fino a 10</MenuItem>,
                                            <MenuItem key={AnnualForeignInvoicesEnum.FOREIGN_1} value={AnnualForeignInvoicesEnum.FOREIGN_1}>Da 10 a 100</MenuItem>,
                                            <MenuItem key={AnnualForeignInvoicesEnum.FOREIGN_2} value={AnnualForeignInvoicesEnum.FOREIGN_2}>Oltre 100</MenuItem>
                                        ]}
                                        label="Fatture estere annue"
                                        onChange={(e) => dispatch(setUpdateCompanySettingAnnualForeignInvoices(foreignToNumberMap.get(e.target.value as AnnualForeignInvoicesEnum) === company?.annualForeignInvoices ? null : foreignToNumberMap.get(e.target.value as AnnualForeignInvoicesEnum)))}
                                        disabled={requestToConfirm?.updateStatus === UpdateApprovationEnum.IDLE || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id) || company?.state === CompanyStatusEnum.RegistryPending}
                                    />
                                </div>
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <SelectComponent
                                        id={"company-quotation-wizard-atecos"}
                                        label="Numero Sezionali/Registri"
                                        value={requestToConfirmState.updateCompanySettingsCreationRequest.atecoCodesNumber === null ? numberToAtecoMap(company?.atecoCodesNumber!) : numberToAtecoMap(requestToConfirmState.updateCompanySettingsCreationRequest.atecoCodesNumber)}
                                        menuItems={[
                                            <MenuItem key={AtecosEnum.ATECOS_0} value={AtecosEnum.ATECOS_0}>Fino a 2</MenuItem>,
                                            <MenuItem key={AtecosEnum.ATECOS_1} value={AtecosEnum.ATECOS_1}>Da 3 a 4</MenuItem>,
                                            <MenuItem key={AtecosEnum.ATECOS_2} value={AtecosEnum.ATECOS_2}>Più di 4</MenuItem>
                                        ]}
                                        onChange={(e) => dispatch(setUpdateCompanySettingAtecoCodesNumber(atecoToNumberMap.get(e.target.value as AtecosEnum) === company?.atecoCodesNumber ? null : atecoToNumberMap.get(e.target.value as AtecosEnum)))}
                                        disabled={requestToConfirm?.updateStatus === UpdateApprovationEnum.IDLE || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id) || company?.state === CompanyStatusEnum.RegistryPending}
                                    />
                                </div>
                                {
                                    (company?.accountingScheme === AccountingSchemeEnum.Ordinary || requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === AccountingSchemeEnum.Ordinary) &&
                                    <div className="col-12 col-lg m-0 p-0">
                                        <SelectComponent
                                            id={"company-quotation-wizard-banks"}
                                            label="Numero estratti conto"
                                            tooltipIcon={<InfoIcon colorBase={colors.primary500} />}
                                            tooltipLabel="Include anche estratti conto per carte di credito, note spese, sistemi di pagamento digitali, ecc."
                                            value={requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === AccountingSchemeEnum.Simplified ? "" : requestToConfirmState.updateCompanySettingsCreationRequest.banksNumber === null ? numberToBankMap(company?.banksNumber!) : numberToBankMap(requestToConfirmState.updateCompanySettingsCreationRequest.banksNumber)}
                                            menuItems={[
                                                <MenuItem key={"company-setting-banks"} value={""}>Seleziona la tipologia...</MenuItem>,
                                                <MenuItem key={BankEnum.BANK_0} value={BankEnum.BANK_0}>Fino a 2</MenuItem>,
                                                <MenuItem key={BankEnum.BANK_1} value={BankEnum.BANK_1}>Da 2 a 5</MenuItem>,
                                                <MenuItem key={BankEnum.BANK_2} value={BankEnum.BANK_2}>Oltre 5</MenuItem>
                                            ]}
                                            onChange={(e) => dispatch(setUpdateCompanySettingBanksNumber(bankToNumberMap.get(e.target.value as BankEnum) === company?.banksNumber ? null : bankToNumberMap.get(e.target.value as BankEnum)))}
                                            disabled={requestToConfirm?.updateStatus === UpdateApprovationEnum.IDLE ||
                                                requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === AccountingSchemeEnum.Simplified ||
                                                (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id) ||
                                                company?.state === CompanyStatusEnum.RegistryPending
                                            }
                                        />
                                    </div>
                                }
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Regimi IVA speciali"}
                                        inputs={[
                                            <SwitchComponent
                                                checked={requestToConfirmState.updateCompanySettingsCreationRequest.specialVatSchemes === null ? company?.specialVatSchemes : requestToConfirmState.updateCompanySettingsCreationRequest.specialVatSchemes}
                                                onChange={(e) => dispatch(setUpdateCompanySettingSpecialVatSchemes(e.target.checked))}
                                                disabled={requestToConfirm?.updateStatus === UpdateApprovationEnum.IDLE || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id) || company?.state === CompanyStatusEnum.RegistryPending}
                                            />,
                                        ]}
                                        style={"column"}
                                    />
                                </div>
                                {
                                    (requestToConfirmState.updateCompanySettingsCreationRequest.specialVatSchemes || company?.specialVatSchemes) &&
                                    <div className="col-12 col-lg m-0 p-0">
                                        <FormGroup
                                            label={""}
                                            inputs={[
                                                <SelectComponent
                                                    id={"select-special-vat-scheme"}
                                                    value={requestToConfirmState.updateCompanySettingsCreationRequest.specialVatSchemes === false ? "" : requestToConfirmState.updateCompanySettingsCreationRequest.specialVatScheme === null ? company?.specialVatScheme! ?? '' : requestToConfirmState.updateCompanySettingsCreationRequest.specialVatScheme.toString()}
                                                    menuItems={[
                                                        <MenuItem key={'SpecialVatSchemeEnum.SCHEME_1'} value={''}>Nessun regime IVA speciale</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_1} value={SpecialVatSchemeEnum.SCHEME_1}>Agricoltura e pesca</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_2} value={SpecialVatSchemeEnum.SCHEME_2}>Vendita sali e tabacchi</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_3} value={SpecialVatSchemeEnum.SCHEME_3}>Commercio dei fiammiferi</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_4} value={SpecialVatSchemeEnum.SCHEME_4}>Editoria</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_5} value={SpecialVatSchemeEnum.SCHEME_5}>Gestione di servizi di telefonia pubblica</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_6} value={SpecialVatSchemeEnum.SCHEME_6}>Rivendita di documenti di trasporto pubblico e di sosta</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_7} value={SpecialVatSchemeEnum.SCHEME_7}>Intrattenimenti, giochi e altre attività di cui al D.P.R. n. 640/1972</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_8} value={SpecialVatSchemeEnum.SCHEME_8}>Agenzie di viaggi e turismo</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_9} value={SpecialVatSchemeEnum.SCHEME_9}>Agriturismo</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_10} value={SpecialVatSchemeEnum.SCHEME_10}>Vendite a domicilio</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_11} value={SpecialVatSchemeEnum.SCHEME_11}>Rivendita di beni usati, di oggetti d'arte, d'antiquariato o da collezione</MenuItem>,
                                                        <MenuItem key={SpecialVatSchemeEnum.SCHEME_12} value={SpecialVatSchemeEnum.SCHEME_12}>Agenzie di vendite all'asta di oggetti d'arte, antiquariato o da collezione</MenuItem>,
                                                    ]}
                                                    onChange={(e) => {
                                                        dispatch(setUpdateCompanySettingSpecialVatSchemes(true))
                                                        dispatch(setUpdateCompanySettingSpecialVatScheme(e.target.value as SpecialVatSchemeEnum))
                                                    }}
                                                    disabled={requestToConfirm?.updateStatus === UpdateApprovationEnum.IDLE || requestToConfirmState.updateCompanySettingsCreationRequest.specialVatSchemes === false || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id) || company?.state === CompanyStatusEnum.RegistryPending}
                                                />
                                            ]}
                                            style={"column"}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                {
                                    (company?.accountingScheme === AccountingSchemeEnum.Ordinary || requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme === AccountingSchemeEnum.Ordinary) &&
                                    <div className="col-12 col-lg m-0 p-0">
                                        <FormGroup
                                            label={"Gestione servizi"}
                                            inputs={[
                                                <div className="d-flex flex-column" style={{ gap: 26 }}>
                                                    {activityCategories}
                                                </div>
                                            ]}
                                            style={"column"}
                                        />
                                    </div>
                                }
                                <div className="col-12 col-lg m-0 p-0">
                                    {
                                        company?.ivaStartMonth && requestToConfirmState.updateCompanySettingsCreationRequest.activityCategoryIds.includes(1) &&
                                        <div className="d-flex flex-column gap-4">
                                            <FormGroup
                                                label={"Mese di partenza IVA"}
                                                inputs={[
                                                    <SelectComponent
                                                        id={"select-iva-month-start"}
                                                        value={company?.ivaStartMonth.toString() === null ? "Gennaio" : company.ivaStartMonth.toString()}
                                                        menuItems={[
                                                            <MenuItem value='' key={'select-a-month'}>Seleziona mese</MenuItem>,
                                                            <MenuItem value='0' key={'0'}>Gennaio</MenuItem>,
                                                            <MenuItem value='1' key={'1'}>Febbraio</MenuItem>,
                                                            <MenuItem value='2' key={'2'}>Marzo</MenuItem>,
                                                            <MenuItem value='3' key={'3'}>Aprile</MenuItem>,
                                                            <MenuItem value='4' key={'4'}>Maggio</MenuItem>,
                                                            <MenuItem value='5' key={'5'}>Giugno</MenuItem>,
                                                            <MenuItem value='6' key={'6'}>Luglio</MenuItem>,
                                                            <MenuItem value='7' key={'7'}>Agosto</MenuItem>,
                                                            <MenuItem value='8' key={'8'}>Settembre</MenuItem>,
                                                            <MenuItem value='9' key={'9'}>Ottobre</MenuItem>,
                                                            <MenuItem value='10' key={'10'}>Novembre</MenuItem>,
                                                            <MenuItem value='11' key={'11'}>Dicembre</MenuItem>,
                                                        ]}
                                                        disabled
                                                    />
                                                ]}
                                                style={"column"}
                                            />
                                        </div>
                                    }
                                    {
                                        company?.cogeStartMonth && requestToConfirmState.updateCompanySettingsCreationRequest.activityCategoryIds.includes(2) &&
                                        <div className="d-flex flex-column gap-4">
                                            <FormGroup
                                                label={"Mese di partenza Co.Ge."}
                                                inputs={[
                                                    <SelectComponent
                                                        id={"select-coge-month-start"}
                                                        value={company?.cogeStartMonth.toString() === null ? "Gennaio" : company.cogeStartMonth.toString()}
                                                        menuItems={[
                                                            <MenuItem value='' key={'select-a-month'}>Seleziona mese</MenuItem>,
                                                            <MenuItem value='0' key={'0'}>Gennaio</MenuItem>,
                                                            <MenuItem value='1' key={'1'}>Febbraio</MenuItem>,
                                                            <MenuItem value='2' key={'2'}>Marzo</MenuItem>,
                                                            <MenuItem value='3' key={'3'}>Aprile</MenuItem>,
                                                            <MenuItem value='4' key={'4'}>Maggio</MenuItem>,
                                                            <MenuItem value='5' key={'5'}>Giugno</MenuItem>,
                                                            <MenuItem value='6' key={'6'}>Luglio</MenuItem>,
                                                            <MenuItem value='7' key={'7'}>Agosto</MenuItem>,
                                                            <MenuItem value='8' key={'8'}>Settembre</MenuItem>,
                                                            <MenuItem value='9' key={'9'}>Ottobre</MenuItem>,
                                                            <MenuItem value='10' key={'10'}>Novembre</MenuItem>,
                                                            <MenuItem value='11' key={'11'}>Dicembre</MenuItem>,
                                                        ]}
                                                        disabled
                                                    />
                                                ]}
                                                style={"column"}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-lg m-0 p-0">
                                <FormGroup
                                    label={"Area fiscale"}
                                    inputs={[
                                        <SwitchComponent
                                            disabled={company?.state !== CompanyStatusEnum.Active || !keycloak.hasRealmRole('admin') || company.email === null}
                                            checked={companyState.companyEditPremium.premium}
                                            onChange={(_, checked) => dispatch(setCompanyEditPremium(checked))} />,
                                    ]}
                                    style={"column"}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        company?.state === CompanyStatusEnum.Active &&
                        <div className="col-12 m-0 p-0" style={{}}>
                            <div className="neutral-container--padding-large">
                                <HeadingComponent
                                    size={HeadingSize.SM}
                                    label={"Sezionali registri"}
                                    subLabel={atecoLabels(company.atecoCodesNumber)}
                                    buttons={
                                        keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('accountant') ?
                                            [
                                                <ButtonComponent
                                                    disabled={company?.state !== CompanyStatusEnum.Active || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                                    label={"Aggiungi codice"}
                                                    onClick={() => dispatch(setOpenAtecoModal(true))}
                                                    color={Colors.PRIMARY}
                                                    variant={Variant.OUTLINE}
                                                    size={Size.SM}
                                                    iconStyle={IconStyle.OFF}
                                                />
                                            ] : []
                                    }
                                />
                                {
                                    company.atecoCodeIds.length > 0 &&
                                    <div className="neutral-container--padding-small flex-row" >
                                        {atecoList}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {
                        company?.state === CompanyStatusEnum.Active && company.accountingScheme === AccountingSchemeEnum.Ordinary &&
                        <div className="col-12 col-lg m-0 p-0">
                            <div className="neutral-container--padding-large" style={{ height: '100%', flexGrow: 2 }}>
                                <HeadingComponent
                                    size={HeadingSize.SM}
                                    label={"Numero estratti conto"}
                                    subLabel={banksLabel(company.banksNumber)}
                                    buttons={keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('accountant') ?
                                        [
                                            <ButtonComponent
                                                disabled={company?.state !== CompanyStatusEnum.Active || (keycloak.hasRealmRole('accountant') && companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id)}
                                                label={"Aggiungi banca"}
                                                onClick={() => dispatch(setOpenBankModal(true))}
                                                color={Colors.PRIMARY}
                                                variant={Variant.OUTLINE}
                                                size={Size.SM}
                                                iconStyle={IconStyle.OFF}
                                            />
                                        ] : []
                                    }
                                />
                                <div style={{ overflow: 'auto', gap: '16px', display: 'flex', flexDirection: 'column', width: '100%', paddingRight: '16px', height: 'auto' }}>
                                    {bankAccounts}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <AtecoModal />
            <BankModal />
            <ConfirmOrDenyModal
                open={companyState.openDeleteBankModal}
                handleClose={() => dispatch(setOpenDeleteBankModal(false))}
                title={"Elimina banca"}
                description={"Sei sicuro di voler eliminare la banca?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    if (company !== undefined) {
                        let newBankList = [...company.bankingAccounts]
                        dispatch(editBankAccounts({ companyId: company.id.toString(), data: { bankingAccounts: newBankList.filter(bank => newBankList.indexOf(bank) !== bankIndexToDelete) } }))
                        dispatch(setOpenDeleteBankModal(false))
                    }
                }}
            />
        </div>
    )
}