import { Collapse } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { CheckboxComponent } from "../checkbox";
import { CheckboxSize, CheckboxStyle } from "../checkbox/dto";
import { AccountingSchemeMap } from "../company/dto";
import { findCompanyById } from "../company/slice";
import { AccountingSchemeEnum, AnnualForeignInvoicesEnum, AtecosEnum, BankEnum, JournalEnum, PeriodicityEnum, numberToAtecoMap, numberToBankMap, numberToForeignMap, numberToJournalMap } from "../companyQuotationWizard/dto";
import { atecoLabelMap, banksLabelMap, foreignLabelMap, journalLabelMap } from "../companyQuotationWizard/summary";
import { FormGroup } from "../formGroup";
import { CheckIcon } from "../icons/check";
import { ChevronDownIcon } from "../icons/chevronDown";
import { SendIcon } from "../icons/send";
import { XIcon } from "../icons/xIcon";
import { ModalComponent } from "../modal";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import colors from '../utils/index.module.scss';
import { UpdateApprovationEnum } from "./dto";
import { confirmOrDenyRequest, findRequestToConfirm, setCompanyToRequestModal, setCompanyToRequestSettedId, setUpdateCompanySettingCompanyId, setUpdateCompanySettingPrice } from "./slice";

export const journalDescrutiveLabelMap = new Map<JournalEnum, ReactNode>([
    [JournalEnum.JOURNAL_0,
    <p className="m-0 p-0 text text--lg typography--regular" style={{ color: colors.success500 }}>
        {'Fino a '}
        <span className="typography--semibold">
            5.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_1,
    <p className="m-0 p-0 text text--lg typography--regular typography-destructive--500" style={{ color: colors.success500 }}>
        {'Da '}
        <span className="typography--semibold">
            5.000 movimenti
        </span>
        {' a '}
        <span className="typography--semibold">
            10.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_2,
    <p className="m-0 p-0 text text--lg typography--regular typography-destructive--500" style={{ color: colors.success500 }}>
        {'Da '}
        <span className="typography--semibold">
            10.000 movimenti
        </span>
        {' a '}
        <span className="typography--semibold">
            15.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_3,
    <p className="m-0 p-0 text text--lg typography--regular typography-destructive--500" style={{ color: colors.success500 }}>
        {'Da '}
        <span className="typography--semibold">
            15.000 movimenti
        </span>
        {' a '}
        <span className="typography--semibold">
            20.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_4,
    <p className="m-0 p-0 text text--lg typography--regular typography-destructive--500" style={{ color: colors.success500 }}>
        {'Da '}
        <span className="typography--semibold">
            20.000 movimenti
        </span>
        {' a '}
        <span className="typography--semibold">
            30.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_5,
    <p className="m-0 p-0 text text--lg typography--regular typography-destructive--500" style={{ color: colors.success500 }}>
        {'Da '}
        <span className="typography--semibold">
            30.000 movimenti
        </span>
        {' a '}
        <span className="typography--semibold">
            50.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_6,
    <p className="m-0 p-0 text text--lg typography--regular typography-destructive--500" style={{ color: colors.success500 }}>
        {'Oltre '}
        <span className="typography--semibold">
            50.000 movimenti
        </span>
    </p>
    ]
])
export const banksDestructiveLabelMap = new Map<BankEnum, ReactNode>([
    [BankEnum.BANK_0,
    <p className="m-0 p-0 text text--lg typography--semibold typography-destructive--500" style={{ color: colors.success500 }}>
        Fino a 2 estratti conto
    </p>
    ],
    [BankEnum.BANK_1,
    <p className="m-0 p-0 text text--lg typography--regular typography-destructive--500" style={{ color: colors.success500 }}>
        {'Da '}
        <span className="typography--semibold">
            2 a 5 estratti conto
        </span>
    </p>
    ],
    [BankEnum.BANK_2,
    <p className="m-0 p-0 text text--lg typography--regular typography-destructive--500" style={{ color: colors.success500 }}>
        {'Più di '}
        <span className="typography--semibold">
            5 estratti conto
        </span>
    </p>
    ]
])

export const foreignDestructiveLabelMap = new Map<AnnualForeignInvoicesEnum, ReactNode>([
    [AnnualForeignInvoicesEnum.FOREIGN_EMPTY,
    <p className="m-0 p-0 text text--lg typography--regular" style={{ color: colors.success500 }}>
        {'Nessuna  '}
        <span className="typography--semibold">
            fattura cartacea
        </span>
    </p>
    ],
    [AnnualForeignInvoicesEnum.FOREIGN_0,
    <p className="m-0 p-0 text text--lg typography--regular" style={{ color: colors.success500 }}>
        {'Fino a  '}
        <span className="typography--semibold">
            10 fatture cartacee
        </span>
    </p>
    ],
    [AnnualForeignInvoicesEnum.FOREIGN_1,
    <p className="m-0 p-0 text text--lg typography--regular" style={{ color: colors.success500 }}>
        {'Da '}
        <span className="typography--semibold">
            10
        </span>
        {' a '}
        <span className="typography--semibold">
            100 fatture cartacee
        </span>
    </p>
    ],
    [AnnualForeignInvoicesEnum.FOREIGN_2,
    <p className="m-0 p-0 text text--lg typography--regular" style={{ color: colors.success500 }}>
        {'Più di '}
        <span className="typography--semibold">
            100 fatture cartacee
        </span>
    </p>
    ]
])

export const atecoDestructiveLabelMap = new Map<AtecosEnum, ReactNode>([
    [AtecosEnum.ATECOS_0,
    <p className="m-0 p-0 text text--lg typography--semibold" style={{ color: colors.success500 }}>
        Fino a 2 sezionali/registri
    </p>
    ],
    [AtecosEnum.ATECOS_1,
    <p className="m-0 p-0 text text--lg typography--regular" style={{ color: colors.success500 }}>
        <span className="typography--semibold">
            Da 3 a 4 sezionali/registri
        </span>
    </p>
    ],
    [AtecosEnum.ATECOS_2,
    <p className="m-0 p-0 text text--lg typography--regular" style={{ color: colors.success500 }}>
        {'Più di '}
        <span className="typography--semibold">
            Più di 4 sezionali/registri
        </span>
    </p>
    ]
])

export function RequestToConfirmModal() {
    const companyToConfirmState = useAppSelector(state => state.companyToConfirm)
    const companyState = useAppSelector(state => state.company)
    const activityState = useAppSelector(state => state.activity)
    const requestToConfirState = useAppSelector(state => state.requestToConfirm)

    const dispatch = useAppDispatch()

    const [price, setPrice] = useState<Number | undefined>(0)

    useEffect(() => {
        if (requestToConfirState.companyToRequestModal) {
            if (requestToConfirState.companyToRequestSettedId !== '') {
                dispatch(findRequestToConfirm(requestToConfirState.companyToRequestSettedId))
            }
        }
    }, [requestToConfirState.companyToRequestModal])

    useEffect(() => {
        if (requestToConfirState.findRequestToConfirmStatus === "successfully") {
            setPrice(requestToConfirState.findRequestToConfirmResponse?.price)
            dispatch(setUpdateCompanySettingPrice(requestToConfirState.findRequestToConfirmResponse?.price))
            dispatch(setUpdateCompanySettingCompanyId(requestToConfirState.findRequestToConfirmResponse?.companyId))
            dispatch(findCompanyById(requestToConfirState.findRequestToConfirmResponse?.companyId!))
        }
    }, [requestToConfirState.findRequestToConfirmStatus])

    if ((companyState.findCompanyStatus === 'failed' || companyState.findCompanyResponse === undefined) && companyToConfirmState.companyToConfirmModal && (requestToConfirState.findRequestToConfirmStatus === 'failed' || requestToConfirState.findRequestToConfirmResponse === undefined)) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    const company = companyState.findCompanyResponse
    const request = requestToConfirState.findRequestToConfirmResponse

    let activityCategory: string = ''
    if (company?.activityCategoryIds !== null && company?.activityCategoryIds !== undefined && company.activityCategoryIds.length > 0) {
        company.activityCategoryIds.forEach((category, _index) => {
            if (activityState.findAllActivityCategoryResponse !== undefined && activityState.findAllActivityCategoryResponse.data !== undefined && activityState.findAllActivityCategoryResponse.data.length > 0) {
                activityState.findAllActivityCategoryResponse.data.forEach(_category => {
                    if (_category.id === category) {
                        let addString = ''
                        if (_index === 0) {
                            addString = ' '
                        } else if (company.activityCategoryIds !== null && _index === company.activityCategoryIds.length - 1) {
                            addString = ' e '
                        } else {
                            addString = ', '
                        }
                        activityCategory = activityCategory + addString + _category.name
                    }
                })
            }
        })
    }

    let activityCategoryRequest: string = ''
    if (request?.activityCategoryIds !== null && request?.activityCategoryIds !== undefined && request.activityCategoryIds.length > 0) {
        request.activityCategoryIds.forEach((category, _index) => {
            if (activityState.findAllActivityCategoryResponse !== undefined && activityState.findAllActivityCategoryResponse.data !== undefined && activityState.findAllActivityCategoryResponse.data.length > 0) {
                activityState.findAllActivityCategoryResponse.data.forEach(_category => {
                    if (_category.id === category) {
                        let addString = ''
                        if (_index === 0) {
                            addString = ' '
                        } else if (request.activityCategoryIds !== null && _index === request.activityCategoryIds.length - 1) {
                            addString = ' e '
                        } else {
                            addString = ', '
                        }
                        activityCategoryRequest = activityCategoryRequest + addString + _category.name
                    }
                })
            }
        })
    }

    return (
        <ModalComponent
            open={requestToConfirState.companyToRequestModal}
            handleClose={() => { dispatch(setCompanyToRequestModal(false)); dispatch(setCompanyToRequestSettedId('')) }}
            width="large"
        >
            <div
                style={{
                    padding: '16px 24px 24px 24px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '24px',
                    minWidth: '480px'
                }}
            >
                {
                    companyState.findCompanyStatus === 'loading' ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}><SpinnerComponent size={"small"} /></div>
                    ) : (
                        <>
                            <div className="d-flex align-items-center justify-content-start" style={{ width: "100%" }}>
                                <span className="text text--lg typography--semibold typography--black">Modifica impostazioni azienda</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", flexDirection: "row", gap: 16, width: "100%" }}>
                                <div key={'company-' + company?.id} style={{ display: 'flex', flexDirection: 'column', gap: '18px', width: '100%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                                            <CheckboxComponent size={CheckboxSize.SM} style={CheckboxStyle.SQUARE} checked disabled />
                                            <p className="m-0 p-0 text text--lg typography--medium typography-neutral--700">
                                                {'Azienda ' + (company?.id)}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px', justifyContent: 'flex-end' }}>
                                            <span className="text text--lg typography--medium typography-neutral--700">{'€ ' + company?.price.toLocaleString('it-IT', { useGrouping: true })}</span>
                                            <ChevronDownIcon colorBase={colors.neutral500} />
                                        </div>
                                    </div>
                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                        <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 0px 52px', gap: 8 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                {request?.accountingScheme === null ? <CheckIcon /> : <XIcon colorBase={colors.destructive500} size="20" />}
                                                <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                                    {'Contabilità '}
                                                    <span className="typography--semibold">
                                                        {company?.accountingScheme === AccountingSchemeEnum.Ordinary ? 'Ordinaria' : 'Semplificata'}
                                                    </span>
                                                </p>
                                            </div>
                                            {
                                                (company?.accountingScheme === AccountingSchemeEnum.Ordinary || request?.accountingScheme === AccountingSchemeEnum.Ordinary) &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    {request?.journal === null ? <CheckIcon /> : <XIcon colorBase={colors.destructive500} size="20" />}
                                                    {company?.journal !== undefined && journalLabelMap.get(numberToJournalMap(company.journal))}
                                                </div>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                <CheckIcon />
                                                <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                                    {'Periodicità '}
                                                    <span className="typography--semibold">
                                                        {company?.periodicity === PeriodicityEnum.Monthly ? 'Mensile' : 'Trimestrale'}
                                                    </span>
                                                </p>
                                            </div>
                                            {
                                                (company?.accountingScheme === AccountingSchemeEnum.Ordinary || request?.accountingScheme === AccountingSchemeEnum.Ordinary) &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    {request?.banksNumber === null ? <CheckIcon /> : <XIcon colorBase={colors.destructive500} size="20" />}
                                                    {banksLabelMap.get(numberToBankMap(company?.banksNumber!))}
                                                </div>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                {request?.annualForeignInvoices === null ? <CheckIcon /> : <XIcon colorBase={colors.destructive500} size="20" />}
                                                {company?.annualForeignInvoices !== undefined && foreignLabelMap.get(numberToForeignMap(company.annualForeignInvoices))}
                                            </div>
                                            {
                                                (company?.specialVatSchemes || request?.specialVatSchemes) &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    {request?.specialVatSchemes !== null ? <XIcon colorBase={colors.destructive500} size="20" /> : <CheckIcon />}
                                                    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                                        {'Gestione regimi IVA speciali '}
                                                        <span className="typography--semibold">
                                                        </span>
                                                    </p>
                                                </div>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                {request?.atecoCodesNumber === null ? <CheckIcon /> : <XIcon colorBase={colors.destructive500} size="20" />}
                                                {company?.atecoCodesNumber !== undefined && atecoLabelMap.get(numberToAtecoMap(company.atecoCodesNumber))}
                                            </div>
                                            {
                                                (company?.accountingScheme === AccountingSchemeEnum.Ordinary || request?.accountingScheme === AccountingSchemeEnum.Ordinary) &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    {request?.activityCategoryIds.length === 0 ? <CheckIcon /> : <XIcon colorBase={colors.destructive500} size="20" />}
                                                    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                                        {'Gestione servizi ' + activityCategory}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </Collapse>
                                </div>
                                <div style={{ width: '1px', backgroundColor: colors.neutral80 }} />
                                <div key={'company-' + company?.id} style={{ display: 'flex', flexDirection: 'column', gap: '18px', width: '100%' }}>

                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                                            <CheckboxComponent size={CheckboxSize.SM} style={CheckboxStyle.SQUARE} checked disabled />
                                            <p className="m-0 p-0 text text--lg typography--medium typography-neutral--700">
                                                {'Azienda ' + (company?.id)}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px', justifyContent: 'flex-end' }}>
                                            <span className="text text--lg typography--medium typography-neutral--700">{'€ ' + price?.toLocaleString('it-IT', { useGrouping: true })}</span>
                                            <ChevronDownIcon colorBase={colors.neutral500} />
                                        </div>
                                    </div>
                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                        <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 0px 52px', gap: 8 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                <CheckIcon />
                                                <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                                    {'Contabilità '}
                                                    <span className="typography--semibold" style={{ color: request?.accountingScheme === null ? colors.neutral500 : colors.success500 }}>
                                                        {AccountingSchemeMap.get(request?.accountingScheme === null ? company?.accountingScheme as AccountingSchemeEnum : request?.accountingScheme as AccountingSchemeEnum)}
                                                    </span>
                                                </p>
                                            </div>
                                            {
                                                (company?.accountingScheme === AccountingSchemeEnum.Ordinary || request?.accountingScheme === AccountingSchemeEnum.Ordinary) &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    <CheckIcon />
                                                    {request?.journal === null ? journalLabelMap.get(numberToJournalMap(company?.journal!)) : journalDescrutiveLabelMap.get(numberToJournalMap(request?.journal!))}
                                                </div>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                <CheckIcon />
                                                <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                                    {'Periodicità '}
                                                    <span className="typography--semibold">
                                                        {company?.periodicity === PeriodicityEnum.Monthly ? 'Mensile' : 'Trimestrale'}
                                                    </span>
                                                </p>
                                            </div>
                                            {
                                                (company?.accountingScheme === AccountingSchemeEnum.Ordinary || request?.accountingScheme === AccountingSchemeEnum.Ordinary) &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    <CheckIcon />
                                                    <span>
                                                        {request?.banksNumber === null ? banksLabelMap.get(numberToBankMap(company?.banksNumber!)) : banksDestructiveLabelMap.get(numberToBankMap(request?.banksNumber!))}
                                                    </span>

                                                </div>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                <CheckIcon />
                                                {request?.annualForeignInvoices === null ? foreignLabelMap.get(numberToForeignMap(company?.annualForeignInvoices!)) : foreignDestructiveLabelMap.get(numberToForeignMap(request?.annualForeignInvoices!))}
                                            </div>
                                            {
                                                (request?.specialVatSchemes !== null || request?.specialVatScheme || company?.specialVatSchemes) &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    <CheckIcon />
                                                    <p className="m-0 p-0 text text--lg typography--regular" style={{ color: request?.specialVatSchemes !== null ? colors.success500 : colors.neutral500 }}>
                                                        {'Gestione regimi IVA speciali '}
                                                        <span className="typography--semibold">
                                                        </span>
                                                    </p>
                                                </div>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                <CheckIcon />
                                                {request?.atecoCodesNumber === null ? atecoLabelMap.get(numberToAtecoMap(company?.atecoCodesNumber!)) : atecoDestructiveLabelMap.get(numberToAtecoMap(request?.atecoCodesNumber!))}
                                            </div>
                                            {
                                                (company?.accountingScheme === AccountingSchemeEnum.Ordinary || request?.accountingScheme === AccountingSchemeEnum.Ordinary) &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    <CheckIcon />
                                                    <span className="m-0 p-0 text text--lg typography--regular" style={{ color: request?.activityCategoryIds.length === 0 ? colors.neutral500 : colors.success500 }}>
                                                        {'Gestione servizi ' + (activityCategoryRequest !== '' ? activityCategoryRequest : activityCategory)}
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '1px', backgroundColor: colors.neutral80 }} />
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', padding: '0 52px' }}>
                                <FormGroup label="Totale annuale (+ IVA 22%)" inputs={[
                                    <NumericFormat
                                        key={"company-to-confirm-price"}
                                        prefix="€ "
                                        defaultValue={requestToConfirState.updateCompanySettingsCreationRequest.price?.toLocaleString('it-IT', { useGrouping: true })}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        customInput={TextInput}
                                        onValueChange={(e) => {
                                            dispatch(setUpdateCompanySettingPrice(e.floatValue))
                                        }}
                                        id={"company-to-confirm-price"}
                                        placeholder={"Totale annuale (+ IVA 22%)"}
                                    />
                                ]} style={"column"} />
                            </div>
                            <div className="row m-0 p-0 w-100" style={{ gap: '12px' }}>
                                <div className="col m-0 p-0">
                                    <ButtonComponent
                                        label={"Rifiuta"}
                                        onClick={() => {
                                            dispatch(confirmOrDenyRequest({
                                                id: request?.id!,
                                                data: {
                                                    companyId: requestToConfirState.updateCompanySettingsCreationRequest.companyId,
                                                    updateStatus: UpdateApprovationEnum.CANCELED
                                                }
                                            }))
                                        }}
                                        color={Colors.NEUTRAL}
                                        variant={Variant.OUTLINE}
                                        size={Size.FIT}
                                        iconStyle={IconStyle.RIGHT}
                                        icon={<XIcon colorBase={"i"} size="16" />}
                                    />
                                </div>
                                <div className="col m-0 p-0">
                                    <ButtonComponent
                                        label={"Approva"}
                                        onClick={() => dispatch(confirmOrDenyRequest({
                                            id: request?.id!,
                                            data: {
                                                companyId: requestToConfirState.updateCompanySettingsCreationRequest.companyId,
                                                price: requestToConfirState.updateCompanySettingsCreationRequest.price,
                                                accountantId: "",
                                                updateStatus: UpdateApprovationEnum.RESOLVED
                                            }
                                        }))}
                                        color={Colors.SUCCESS}
                                        variant={Variant.SOLID}
                                        size={Size.FIT}
                                        iconStyle={IconStyle.RIGHT}
                                        icon={<SendIcon colorBase={""} />}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </ModalComponent>
    )
}