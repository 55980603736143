import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { CompanyStatusEnum } from "../company/dto";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { SearchIcon } from "../icons/search";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import { findAllCompaniesToConfirm, setCompaniesToAddCompanyFilter, setCompaniesToAddFinancialAdvisorFilter } from "./slice";

export function CompanyFiltersComponent() {
    const companyToConfirmState = useAppSelector(state => state.companyToConfirm)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const authState = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    let financialAdvisorsMenuItems: ReactNode[] = []

    useEffect(() => {
        dispatch(findAllFinancialAdvisorWithoutPagination())
    }, [])

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading') {
        return (<div><SpinnerComponent size={"small"} /></div>)
    }

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed') {
        return (<div>Si è verificato un errore</div>)
    }

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0) {
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.forEach(financialAdvisor => {
            financialAdvisorsMenuItems.push(
                <MenuItem key={'company-to-confirm-financial-advisor-filter-' + financialAdvisor.id} value={financialAdvisor.id}>{financialAdvisor.name + ' ' + financialAdvisor.surname}</MenuItem>
            )
        })
    }

    return (
        <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
            <div className="col-12 col-lg p-0 m-0">
                <TextInput value={companyToConfirmState.companiesToAddFilters.businessName} onChange={(e) => dispatch(setCompaniesToAddCompanyFilter(e.target.value))} id="company-to-confirm-company-filter" type={"text"} placeholder={"Cerca azienda"} startIcon={<SearchIcon colorBase={""} />} />
            </div>
            {
                keycloak.hasRealmRole('admin') &&
                <div className="col-12 col-lg p-0 m-0">
                    <SelectComponent
                        onChange={(e) => {
                            dispatch(setCompaniesToAddFinancialAdvisorFilter(e.target.value))
                            dispatch(findAllCompaniesToConfirm({
                                page: companyToConfirmState.companiesToAddFilters.page,
                                financialAdvisor: e.target.value,
                                itemsPerPage: companyToConfirmState.companiesToAddFilters.itemsPerPage,
                                operators: companyToConfirmState.companiesToAddFilters.operators,
                                accountant: companyToConfirmState.companiesToAddFilters.accountant,
                                businessName: companyToConfirmState.companiesToAddFilters.businessName,
                                status: companyToConfirmState.companiesToAddFilters.status,
                                contabile: companyToConfirmState.companiesToAddFilters.contabile
                            }))
                        }}
                        id={"companies-to-confirm-financial-advisor-filter"}
                        value={companyToConfirmState.companiesToAddFilters.financialAdvisor}
                        menuItems={
                            [
                                <MenuItem key='' value=''>Tutti i commercialisti</MenuItem>,
                                financialAdvisorsMenuItems
                            ]
                        }
                    />
                </div>
            }
            <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="row p-0 m-0" style={{ gap: '8px' }}>
                    <div className="col p-0 m-0">
                        <ButtonComponent
                            label={"Pulisci"}
                            onClick={() => {
                                if (keycloak.hasRealmRole('financial-advisor')) {
                                    dispatch(setCompaniesToAddFinancialAdvisorFilter(authState.findMeResponse?.id.toString()))
                                } else {
                                    dispatch(setCompaniesToAddFinancialAdvisorFilter(''))
                                }
                                dispatch(setCompaniesToAddCompanyFilter(''))
                                dispatch(findAllCompaniesToConfirm({ businessName: '', accountant: '', operators: '', financialAdvisor: keycloak.hasRealmRole('financial-advisor') && authState.findMeResponse !== undefined ? authState.findMeResponse.id.toString() : '', status: [CompanyStatusEnum.Pending], page: companyToConfirmState.companiesToAddFilters.page, itemsPerPage: companyToConfirmState.companiesToAddFilters.itemsPerPage, contabile: '' }))
                            }}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.MD}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col p-0 m-0">
                        <ButtonComponent label={"Applica"} onClick={() => dispatch(findAllCompaniesToConfirm(companyToConfirmState.companiesToAddFilters))} color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                    </div>
                </div>
            </div>
        </div>
    )
}