import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { CompanyStatusEnum } from "../company/dto";
import { findAllCompanies } from "../company/slice";
import { SearchIcon } from "../icons/search";
import { keycloak } from "../keycloak";
import { findAllOperators } from "../operator/slice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import { findAllAccountantsFilter, findAllFinancialAdvisorsFilter, setAccountantFilter, setBusinessNameFilter, setFinancialAdvisorFilter, setOperatorsFilter, setStatusFilter } from "./slice";

export function DashboardFiltersComponent() {
    const dashboardState = useAppSelector(state => state.dashboard)
    const authState = useAppSelector(state => state.auth)
    const companyState = useAppSelector(state => state.company)
    const operatorState = useAppSelector(state => state.operator)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllFinancialAdvisorsFilter({
            itemsPerPage: 0,
            page: 0,
            accountant: keycloak.hasRealmRole('accountant') ? (authState.findMeResponse?.id.toString() || '') : '',
            financialAdvisor: '',
            allofall: false
        }))
        if (!keycloak.hasRealmRole('operator')) {
            dispatch(findAllAccountantsFilter({
                itemsPerPage: 0,
                page: 0,
                accountant: keycloak.hasRealmRole('accountant') ? (authState.findMeResponse?.id.toString() || '') : '',
                financialAdvisor: '',
                allofall: false
            }))
        }
        if (keycloak.hasRealmRole('financial-advisor')) {
            dispatch(findAllOperators({ financialAdvisor: companyState.findAllOperatorsFinancialAdvisorFilter, operator: '', itemsPerPage: 0, page: 0 }))
        }
    }, [])

    if (
        dashboardState.findAllFinancialAdvisorsFilterStatus === 'loading' ||
        dashboardState.findAllAccountantsFilterStatus === 'loading'
    ) {
        return (<div><SpinnerComponent size={"small"} /></div>)
    }

    if (
        dashboardState.findAllFinancialAdvisorsFilterStatus === 'failed' ||
        dashboardState.findAllAccountantsFilterStatus === 'failed'
    ) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    let financialAdvisorMenuItems: ReactNode[] = []
    let accountantMenuItems: ReactNode[] = []
    let operatorsMenuItems: ReactNode[] = []

    if (
        dashboardState.findAllFinancialAdvisorsFilterStatus === 'successfully' &&
        dashboardState.findAllFinancialAdvisorsFilterResponse !== undefined &&
        dashboardState.findAllFinancialAdvisorsFilterResponse.data !== undefined &&
        dashboardState.findAllFinancialAdvisorsFilterResponse.data.length > 0
    ) {
        dashboardState.findAllFinancialAdvisorsFilterResponse.data.forEach(financialAdvisor => {
            financialAdvisorMenuItems.push(
                <MenuItem key={financialAdvisor.id} value={financialAdvisor.id}>{financialAdvisor.name + ' ' + financialAdvisor.surname}</MenuItem>,
            )
        })
    }

    if (
        dashboardState.findAllAccountantsFilterStatus === 'successfully' &&
        dashboardState.findAllAccountantsFilterResponse !== undefined &&
        dashboardState.findAllAccountantsFilterResponse.data !== undefined &&
        dashboardState.findAllAccountantsFilterResponse.data.length > 0

    ) {
        dashboardState.findAllAccountantsFilterResponse.data.forEach(accountant => {
            accountantMenuItems.push(
                <MenuItem key={accountant.id} value={accountant.id}>{accountant.name + ' ' + accountant.surname}</MenuItem>,
            )
        })
    }

    if (
        operatorState.findAllOperatorStatus === 'successfully' &&
        operatorState.findAllOperatorResponse !== undefined &&
        operatorState.findAllOperatorResponse.data !== undefined &&
        operatorState.findAllOperatorResponse.data.length > 0

    ) {
        operatorState.findAllOperatorResponse.data.forEach(operator => {
            operatorsMenuItems.push(
                <MenuItem key={operator.id} value={operator.id}>{operator.name + ' ' + operator.surname}</MenuItem>,
            )
        })
    }

    return (
        <div style={{ gap: '16px' }} className="row m-0 p-0">
            <div className="col-12 col-lg p-0 m-0">
                <TextInput
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            dispatch(findAllCompanies(dashboardState.companyFilters))
                        }
                    }}
                    onChange={(e) => dispatch(setBusinessNameFilter(e.target.value))}
                    value={dashboardState.companyFilters.businessName}
                    id="dashboard-company-filter"
                    type={"text"}
                    placeholder={"Cerca azienda"}
                    startIcon={<SearchIcon colorBase={""}
                    />}
                />
            </div>
            {
                !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('operator') &&
                <div className="col-12 col-lg p-0 m-0">
                    <SelectComponent
                        value={dashboardState.companyFilters.accountant}
                        id={"dashboard-accountant-filter"}
                        onChange={(e) => {
                            dispatch(findAllCompanies({
                                accountant: e.target.value,
                                financialAdvisor: dashboardState.companyFilters.financialAdvisor,
                                businessName: dashboardState.companyFilters.businessName,
                                operators: dashboardState.companyFilters.operators,
                                page: dashboardState.companyFilters.page,
                                itemsPerPage: dashboardState.companyFilters.itemsPerPage,
                                status: dashboardState.companyFilters.status,
                                contabile: dashboardState.companyFilters.contabile
                            }))
                            dispatch(setAccountantFilter(e.target.value))
                        }}
                        menuItems={
                            [
                                <MenuItem key='' value=''>Tutti i contabili</MenuItem>,
                                accountantMenuItems
                            ]
                        }
                    />
                </div>
            }
            {
                keycloak.hasRealmRole('financial-advisor') &&
                <div className="col-12 col-lg p-0 m-0">
                    <SelectComponent
                        value={dashboardState.companyFilters.operators}
                        id={"dashboard-operators-filter"}
                        onChange={(e) => {
                            dispatch(setOperatorsFilter(e.target.value))
                            dispatch(findAllCompanies({
                                accountant: dashboardState.companyFilters.accountant,
                                financialAdvisor: dashboardState.companyFilters.financialAdvisor,
                                businessName: dashboardState.companyFilters.businessName,
                                operators: e.target.value,
                                page: dashboardState.companyFilters.page,
                                itemsPerPage: dashboardState.companyFilters.itemsPerPage,
                                status: dashboardState.companyFilters.status,
                                contabile: dashboardState.companyFilters.contabile
                            }))
                        }}
                        menuItems={
                            [
                                <MenuItem key='' value=''>Tutti i collaboratori</MenuItem>,
                                operatorsMenuItems
                            ]
                        }
                    />
                </div>
            }
            {
                (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('accountant') || keycloak.hasRealmRole('accountant') || keycloak.hasRealmRole('accountant') || keycloak.hasRealmRole('accountant') || keycloak.hasRealmRole('accountant')) && <div className="col-12 col-lg p-0 m-0">
                    <SelectComponent
                        id={"dashboard-financial-advisor-filter"}
                        onChange={(e) => {
                            dispatch(setFinancialAdvisorFilter(e.target.value))
                            dispatch(findAllCompanies({
                                accountant: dashboardState.companyFilters.accountant,
                                financialAdvisor: e.target.value,
                                businessName: dashboardState.companyFilters.businessName,
                                operators: dashboardState.companyFilters.operators,
                                page: dashboardState.companyFilters.page,
                                itemsPerPage: dashboardState.companyFilters.itemsPerPage,
                                status: dashboardState.companyFilters.status,
                                contabile: dashboardState.companyFilters.contabile
                            }))
                        }}
                        value={dashboardState.companyFilters.financialAdvisor}
                        menuItems={
                            [
                                <MenuItem key='' value=''>Tutti i commercialisti</MenuItem>,
                                financialAdvisorMenuItems
                            ]
                        }
                    />
                </div>
            }
            <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="row p-0 m-0" style={{ gap: '8px' }}>
                    <div className="col p-0 m-0">
                        <ButtonComponent
                            label={"Pulisci"}
                            onClick={() => {
                                dispatch(setBusinessNameFilter(''))
                                dispatch(setOperatorsFilter(''))
                                if (keycloak.hasRealmRole('financial-advisor')) {
                                    dispatch(setFinancialAdvisorFilter(authState.findMeResponse?.id.toString()))
                                } else {
                                    dispatch(setFinancialAdvisorFilter(''))
                                }
                                if (keycloak.hasRealmRole('accountant')) {
                                    dispatch(setAccountantFilter(authState.findMeResponse?.id.toString()))
                                } else {
                                    dispatch(setAccountantFilter(''))
                                }
                                dispatch(setStatusFilter([CompanyStatusEnum.Active]))
                                dispatch(findAllCompanies({
                                    page: dashboardState.companyFilters.page,
                                    itemsPerPage: dashboardState.companyFilters.itemsPerPage,
                                    businessName: '',
                                    operators: '',
                                    accountant: keycloak.hasRealmRole('accountant') && authState.findMeResponse !== undefined ? authState.findMeResponse.id.toString() : '',
                                    financialAdvisor: keycloak.hasRealmRole('financial-advisor') && authState.findMeResponse !== undefined ? authState.findMeResponse.id.toString() : '',
                                    status: [CompanyStatusEnum.Active],
                                    contabile: ''
                                }))
                            }}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.MD}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col p-0 m-0">
                        <ButtonComponent label={"Applica"} onClick={() => dispatch(findAllCompanies(dashboardState.companyFilters))} color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                    </div>
                </div>
            </div>
        </div>
    )
}