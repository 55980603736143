import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../utils"
import { FindAllUpdateCompanySettings, FindAllUpdateCompanySettingsFilters, UpdateApprovationEnum, UpdateCompanySettingsConfirmDTO, UpdateCompanySettingsCreationDTO, UpdateCompanySettingsDTO } from "./dto"
import { NewRequestToConfirmService } from "./service"

interface RequestToConfirmState {
    requestToAddFilters: FindAllUpdateCompanySettingsFilters
    findAllUpdateCompanySettingsStatus: PromiseStatuses
    findAllUpdateCompanySettingsResponse?: FindAllUpdateCompanySettings
    updateCompanySettingsCreationRequest: UpdateCompanySettingsCreationDTO
    updateCompanySettingsStatus: PromiseStatuses
    companyToRequestSettedId: string
    companyToRequestModal: boolean
    findRequestToConfirmStatus: PromiseStatuses
    findRequestToConfirmResponse?: UpdateCompanySettingsDTO
    confirmOrDenyRequestStatus: PromiseStatuses
    confirmOrDenyRequestRequest: UpdateCompanySettingsConfirmDTO
}

const initialState: RequestToConfirmState = {
    requestToAddFilters: {
        companyName: "",
        itemsPerPage: 25,
        page: 0,
        status: UpdateApprovationEnum.IDLE,
        financialAdvisor: ""
    },
    findAllUpdateCompanySettingsStatus: "idle",
    updateCompanySettingsCreationRequest: {
        companyId: "",
        price: 0,
        accountingScheme: null,
        atecoCodesNumber: null,
        annualForeignInvoices: null,
        specialVatSchemes: null,
        specialVatScheme: null,
        banksNumber: null,
        journal: null,
        activityCategoryIds: [],
        updateStatus: null
    },
    updateCompanySettingsStatus: "idle",
    companyToRequestSettedId: "",
    companyToRequestModal: false,
    findRequestToConfirmStatus: "idle",
    confirmOrDenyRequestStatus: "idle",
    confirmOrDenyRequestRequest: {
        companyId: "",
        price: 0,
        accountantId: "",
        updateStatus: UpdateApprovationEnum.IDLE
    }
}

export const findAllRequestToConfirm = createAsyncThunk(
    'requestToConfirm/findAllRequestToConfirm',
    async (request: FindAllUpdateCompanySettingsFilters, thunkApi): Promise<FindAllUpdateCompanySettings> => {
        const requestToConfirmService = NewRequestToConfirmService()

        return requestToConfirmService.findAllRequestToConfirm(request)
    },
)

export const updateCompanySettings = createAsyncThunk(
    'requestToConfirm/updateCompanySettings',
    async (request: UpdateCompanySettingsCreationDTO, thunkApi): Promise<void> => {
        const requestToConfirmService = NewRequestToConfirmService()

        return requestToConfirmService.updateCompanySettings(request)
    },
)

export const findRequestToConfirm = createAsyncThunk(
    'requestToConfirm/findRequestToConfirm',
    async (id: string, thunkApi): Promise<UpdateCompanySettingsDTO> => {
        const requestToConfirmService = NewRequestToConfirmService()

        return requestToConfirmService.findRequestToConfirm(id)
    },
)

export const confirmOrDenyRequest = createAsyncThunk(
    'requestToConfirm/confirmOrDenyRequest',
    async (request: { id: string, data: UpdateCompanySettingsConfirmDTO }): Promise<void> => {
        const requestToConfirmService = NewRequestToConfirmService()

        return requestToConfirmService.confirmOrDenyRequest(request.id, request.data)
    },
)

const requestToConfirmSlice = createSlice({
    name: 'requestToConfirm/slice',
    initialState,
    reducers: {
        setCompanyToRequestSettedId: (state, action) => {
            state.companyToRequestSettedId = action.payload
        },
        setFindAllUpdateCompanySettingsStatusStatus: (state, action) => {
            state.findAllUpdateCompanySettingsStatus = action.payload
        },
        setUpdateCompanySettingsCompanyNameFilter: (state, action) => {
            state.requestToAddFilters.companyName = action.payload
        },
        setUpdateCompanySettingsFinancialAdvisorFilter: (state, action) => {
            state.requestToAddFilters.financialAdvisor = action.payload
        },
        setUpdateCompanySettingsStatusFilter: (state, action) => {
            state.requestToAddFilters.status = action.payload
        },
        setUpdateCompanySettingAccountingScheme: (state, action) => {
            state.updateCompanySettingsCreationRequest.accountingScheme = action.payload
        },
        setUpdateCompanySettingAtecoCodesNumber: (state, action) => {
            state.updateCompanySettingsCreationRequest.atecoCodesNumber = action.payload
        },
        setUpdateCompanySettingAnnualForeignInvoices: (state, action) => {
            state.updateCompanySettingsCreationRequest.annualForeignInvoices = action.payload
        },
        setUpdateCompanySettingSpecialVatSchemes: (state, action) => {
            state.updateCompanySettingsCreationRequest.specialVatSchemes = action.payload
        },
        setUpdateCompanySettingSpecialVatScheme: (state, action) => {
            state.updateCompanySettingsCreationRequest.specialVatScheme = action.payload
        },
        setUpdateCompanySettingBanksNumber: (state, action) => {
            state.updateCompanySettingsCreationRequest.banksNumber = action.payload
        },
        setUpdateCompanySettingJournal: (state, action) => {
            state.updateCompanySettingsCreationRequest.journal = action.payload
        },
        setUpdateCompanySettingActivityCategoryIds: (state, action) => {
            state.updateCompanySettingsCreationRequest.activityCategoryIds = action.payload
        },
        setUpdateCompanySettingCompanyId: (state, action) => {
            state.updateCompanySettingsCreationRequest.companyId = action.payload
        },
        setUpdateCompanySettingPrice: (state, action) => {
            state.updateCompanySettingsCreationRequest.price = action.payload
        },
        setResetUpdateCompanySettingsRequest: (state, action) => {
            state.updateCompanySettingsCreationRequest = {
                companyId: "",
                price: 0,
                accountingScheme: null,
                atecoCodesNumber: null,
                annualForeignInvoices: null,
                specialVatSchemes: null,
                specialVatScheme: null,
                banksNumber: null,
                journal: null,
                activityCategoryIds: [],
                updateStatus: null
            }
        },
        setCompanyToRequestModal: (state, action) => {
            state.companyToRequestModal = action.payload
        },
        setConfirmOrDenyRequestStatus: (state, action) => {
            state.confirmOrDenyRequestStatus = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findAllRequestToConfirm.pending, (state) => {
                state.findAllUpdateCompanySettingsStatus = 'loading'
            })
            .addCase(findAllRequestToConfirm.fulfilled, (state, action) => {
                state.findAllUpdateCompanySettingsStatus = 'successfully'
                state.findAllUpdateCompanySettingsResponse = action.payload
            })
            .addCase(findAllRequestToConfirm.rejected, (state) => {
                state.findAllUpdateCompanySettingsStatus = 'failed'
            })
            .addCase(updateCompanySettings.pending, (state) => {
                state.updateCompanySettingsStatus = 'loading'
            })
            .addCase(updateCompanySettings.fulfilled, (state, action) => {
                state.updateCompanySettingsStatus = 'successfully'
            })
            .addCase(updateCompanySettings.rejected, (state) => {
                state.updateCompanySettingsStatus = 'failed'
            })
            .addCase(findRequestToConfirm.pending, (state) => {
                state.findRequestToConfirmStatus = 'loading'
            })
            .addCase(findRequestToConfirm.fulfilled, (state, action) => {
                state.findRequestToConfirmStatus = 'successfully'
                state.findRequestToConfirmResponse = action.payload
            })
            .addCase(findRequestToConfirm.rejected, (state) => {
                state.findRequestToConfirmStatus = 'failed'
            })
            .addCase(confirmOrDenyRequest.pending, (state) => {
                state.confirmOrDenyRequestStatus = 'loading'
            })
            .addCase(confirmOrDenyRequest.fulfilled, (state, action) => {
                state.confirmOrDenyRequestStatus = 'successfully'
            })
            .addCase(confirmOrDenyRequest.rejected, (state) => {
                state.confirmOrDenyRequestStatus = 'failed'
            })
    },
})

export const {
    setFindAllUpdateCompanySettingsStatusStatus,
    setUpdateCompanySettingsCompanyNameFilter,
    setUpdateCompanySettingsStatusFilter,
    setUpdateCompanySettingAccountingScheme,
    setUpdateCompanySettingActivityCategoryIds,
    setUpdateCompanySettingAnnualForeignInvoices,
    setUpdateCompanySettingAtecoCodesNumber,
    setUpdateCompanySettingBanksNumber,
    setUpdateCompanySettingJournal,
    setUpdateCompanySettingSpecialVatScheme,
    setUpdateCompanySettingSpecialVatSchemes,
    setUpdateCompanySettingCompanyId,
    setResetUpdateCompanySettingsRequest,
    setCompanyToRequestSettedId,
    setCompanyToRequestModal,
    setUpdateCompanySettingPrice,
    setConfirmOrDenyRequestStatus,
    setUpdateCompanySettingsFinancialAdvisorFilter
} = requestToConfirmSlice.actions

export default requestToConfirmSlice.reducer