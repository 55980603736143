import axios from "axios";
import { format, lastDayOfMonth } from "date-fns";
import { keycloak } from "../keycloak";
import { DocumentsResponseDTO, FindAllDocumentsResponse } from "../objects/dto";
import { ActivityCanBeDeletedResponse, ActivityGroupResponse, ActivityResponse, ActivityTypeDTO, ActivityTypesRequestDTO, AcubeAccountDTO, AcubeReconnectResponseDTO, AddAccountantIdDTO, AddContabileIdDTO, AddOperatorDTO, AtecoResponseDTO, BankRequestDTO, CompanyEditPremiumDTO, CompanyResponseDTO, CompanyUnseenInfoDocumentsDTO, CompanyUnseenInfoMessagesDTO, FindAllAtecos, FindAllCompanies, FindAllCompaniesFilters, RegistryEmailDTO, TodosDTO } from "./dto";
import { CompanyService } from "./service";

export class CompanyServiceImpl implements CompanyService {

    public findAllCompanies(filters: FindAllCompaniesFilters): Promise<FindAllCompanies> {
        let url: string = "/api/companies?" +
            "page=" + filters.page +
            "&itemsPerPage=" + filters.itemsPerPage +
            (filters.accountant !== '' ? '&accountant=' + filters.accountant : '') +
            (filters.contabile !== '' ? '&contabile=' + filters.contabile : '') +
            (filters.operators !== '' ? '&operator=' + filters.operators : '') +
            (filters.financialAdvisor !== '' ? '&financialAdvisor=' + filters.financialAdvisor : '') +
            (filters.businessName !== '' ? '&businessName=' + filters.businessName.toLowerCase() : '')
        filters.status.forEach(state => url = url + '&status=' + state)
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findCompany(id: string): Promise<CompanyResponseDTO> {
        let idToUse: string = ''
        if (window.location.pathname.includes('company/operation')) {
            if (
                window.location.pathname.split('company/operation/')[1] === undefined &&
                window.location.pathname.split('company/operation/')[1].split('/')[0] === undefined
            ) {
                idToUse = id
            } else {
                idToUse = window.location.pathname.split('company/operation/')[1].split('/')[0]
            }
        } else if (window.location.pathname.includes('company/settings')) {
            if (
                window.location.pathname.split('company/settings/')[1] === undefined &&
                window.location.pathname.split('company/settings/')[1].split('/')[0] === undefined
            ) {
                idToUse = id
            } else {
                idToUse = window.location.pathname.split('company/settings/')[1].split('/')[0]
            }
        }
        else {
            idToUse = id
        }
        let url: string = "/api/companies/" + idToUse
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public updateRegistry(id: string, data: FormData): Promise<void> {
        let url: string = "/api/companies/" + id + '/registry'
        return axios({
            url: url,
            method: "POST",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public addOperators(companyId: string, data: AddOperatorDTO): Promise<void> {
        let url: string = "/api/companies/" + companyId + '/operators'
        return axios({
            url: url,
            method: "POST",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findAllAtecos(page: number, itemsPerPage: number): Promise<FindAllAtecos> {
        let url: string = "/api/ateco-codes"
            + "?page=" + page +
            "&itemsPerPage=" + itemsPerPage
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findAteco(id: number): Promise<AtecoResponseDTO> {
        let url: string = "/api/ateco-codes/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public addAccountant(companyId: string, data: AddAccountantIdDTO): Promise<void> {
        let url: string = "/api/companies/" + companyId + '/accountant'
        return axios({
            url: url,
            method: "POST",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public addContabili(companyId: string, data: AddContabileIdDTO): Promise<void> {
        let url: string = "/api/companies/" + companyId + '/contabile'
        return axios({
            url: url,
            method: "POST",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editBankAccounts(companyId: string, data: BankRequestDTO): Promise<void> {
        let url: string = "/api/companies/" + companyId + '/bankingAccounts'
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public activityCanBeDeleted(companyId: string): Promise<ActivityCanBeDeletedResponse[]> {
        let url: string = "/api/companies/" + companyId + '/activity-types/canBeDeleted'
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editCompanyActivityType(companyId: string, data: ActivityTypesRequestDTO): Promise<void> {
        let url: string = "/api/companies/" + companyId + '/activity-types'
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editTodos(companyId: string, data: TodosDTO): Promise<void> {
        let url: string = "/api/companies/" + companyId + '/todos'
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findActivityGroup(year: number, companyId: string): Promise<ActivityGroupResponse> {
        let url: string = "/api/activity-groups" +
            (companyId !== '' ? ("?company=" + companyId) : '') +
            (year !== 0 ? ((companyId !== '' ? '&year=' : '?year=') + year) : '')
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findActivityByCompanyId(companyId: number, year: number): Promise<ActivityResponse> {
        let url: string = "/api/activities?companyId=" + companyId + '&year=' + year
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findActivityByGroup(groupId: number): Promise<ActivityResponse> {
        let url: string = "/api/activities?groupId=" + groupId
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findActivityTypeById(id: number): Promise<ActivityTypeDTO> {
        let url: string = "/api/activity-types/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public setActivityCompleted(ids: number[], notes: (string | null)[]): Promise<void> {
        let url: string = "/api/activities/complete"
        return axios({
            url: url,
            method: "PUT",
            data: {
                "activityIds": ids,
                "notes": notes
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public setActivityOpen(ids: number[], notes: (string | null)[]): Promise<void> {
        let url: string = "/api/activities/open"
        return axios({
            url: url,
            method: "PUT",
            data: {
                "activityIds": ids
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findAllDocuments(companyId: number, year: number, period: number | '', page: number, received: 'true' | 'false'): Promise<FindAllDocumentsResponse> {
        const monthMap = new Map<string, number>([
            ['0', 0],
            ['1', 1],
            ['2', 2],
            ['3', 3],
            ['4', 4],
            ['5', 5],
            ['6', 6],
            ['7', 7],
            ['8', 8],
            ['9', 9],
            ['10', 10],
            ['11', 11]
        ])
        let fromDate: string = ''
        let toDate: string = ''
        if (period === '') {
            fromDate = year + '-01-01'
            toDate = year + '-12-31'
        } else {
            fromDate = format(new Date(year, period, 1), 'yyyy-MM-dd')
            toDate = format(lastDayOfMonth(new Date(year, period, 1)), 'yyyy-MM-dd')
        }
        let url: string =
            "/api/document?companyId=" + companyId +
            "&fromDate=" + fromDate +
            "&toDate=" + toDate +
            "&itemsPerPage=15" +
            "&page=" + page +
            "&received=" + received
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteCompany(id: string): Promise<void> {
        let url: string = "/api/companies/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }

    public findDocumentById(id: string): Promise<DocumentsResponseDTO> {
        let url: string = "/api/document/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteDocument(id: string): Promise<void> {
        let url: string = "/api/document/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public completeGroup(id: string): Promise<void> {
        let url: string = "/api/activity-groups/" + id + "/complete"
        return axios({
            url: url,
            method: "PUT",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public getCompanyRegistryPdf(id: string): Promise<string> {
        let url: string = "/api/pdf/company-registry/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public setAtecos(atecos: number[], id: string): Promise<void> {
        let url: string = "/api/companies/" + id + "/ateco-codes"
        return axios({
            url: url,
            method: "POST",
            data: {
                atecoCodeIds: atecos
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editRegistry(id: string, data: FormData): Promise<void> {
        let url: string = "/api/companies/" + id + "/editRegistry"
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findAcubeConnectRequest(id: string): Promise<string> {
        let url: string = "/api/companies/" + id + "/acubeConnectRequest"
        return axios({
            url: url,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findByIdAcubeGetAccount(id: string, uuid: string): Promise<AcubeAccountDTO> {
        let url: string = "/api/companies/" + id + "/acubeGetAccount/" + uuid
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editCompany(id: string, data: FormData): Promise<void> {
        let url: string = "/api/companies/" + id
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }


    public acubeGetTransaction(fiscalId: string, uuid: string, madeOnAfter: string, madeOnBefore: string): Promise<string> {
        let url: string = "/api/companies/" + fiscalId + "/acubeGetTransactions?account=" + uuid + "&madeOnAfter=" + madeOnAfter + "&madeOnBefore=" + madeOnBefore
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public acubeReconnectRequest(id: string, uuid: string): Promise<AcubeReconnectResponseDTO> {
        let url: string = "/api/companies/" + id + "/acubeReconnectRequest/" + uuid
        return axios({
            url: url,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public getCompanyUnseenInfoDocuments(): Promise<CompanyUnseenInfoDocumentsDTO[]> {
        let url: string = "/api/companies/unseenDocuments"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public getCompanyUnseenInfoMessages(topics: string[]): Promise<CompanyUnseenInfoMessagesDTO[]> {
        let url: string = "/api/chats/unseenMessages?"

        const queryParams = topics.map(topic => `topics=${encodeURIComponent(topic)}`).join('&');

        return axios({
            url: url + queryParams,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public setCompanyEditPremium(request: CompanyEditPremiumDTO, id: number): Promise<void> {
        let url: string = "/api/companies/" + id + '/editPremium'
        return axios({
            url: url,
            method: "PUT",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public registryEmail(request: RegistryEmailDTO, id: number): Promise<void> {
        let url: string = "/api/companies/" + id + '/registry-email'
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
