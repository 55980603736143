import { MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { findAllAccountantWithoutPagination } from "../accountant/slice";
import { editMeFinancialAdvisorComplete, findMe, setEditMeFinancialAdvisorRequestAccountantId, setEditMeFinancialAdvisorRequestActivityNotifications, setEditMeFinancialAdvisorRequestBusinessName, setEditMeFinancialAdvisorRequestDescription, setEditMeFinancialAdvisorRequestName, setEditMeFinancialAdvisorRequestOperationsNotifications, setEditMeFinancialAdvisorRequestPhoneNumber, setEditMeFinancialAdvisorRequestSurname, setEditMeFinancialAdvisorStatus, setFinancialAdvisorSuccessPopup, setFindMeStatus } from "../auth/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { ErrorPopup } from "../errorPopup";
import { FileInputComponent } from "../fileInput";
import { findFinancialAdvisorAvatarById, setEditFinancialAdvisorFindAvatarStatus } from "../financialAdvisor/slice";
import { FormGroup } from "../formGroup";
import { HeadingSize } from "../heading/dto";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { LayoutComponent } from "../layout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { SwitchComponent } from "../switch";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";

export function EditFinancialAdvisorView() {
    const dispatch = useAppDispatch()

    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const accountantState = useAppSelector(state => state.accountant)
    const authState = useAppSelector(state => state.auth)

    let formData = new FormData();

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')
    const [openPopup, setOpenPopup] = useState<boolean>(false)

    let accountantsMenuItems: ReactNode[] = []

    useEffect(() => {
        dispatch(findAllAccountantWithoutPagination())
    }, [])

    useEffect(() => {
        if (authState.editMeFinancialAdvisorComplete === 'successfully') {
            dispatch(setFinancialAdvisorSuccessPopup(true))
            dispatch(findMe())
            dispatch(setEditMeFinancialAdvisorStatus('idle'))
        }
    }, [authState.editMeFinancialAdvisorComplete])

    useEffect(() => {
        if (authState.findMeStatus === 'successfully' && authState.findMeResponse !== undefined && authState.findMeResponse.avatarObjectId !== null) {
            dispatch(findFinancialAdvisorAvatarById(authState.findMeResponse.avatarObjectId))
            dispatch(setFindMeStatus("idle"))
        }
    }, [authState.findMeStatus])

    useEffect(() => {
        if (financialAdvisorState.editFinancialAdvisorFindAvatarStatus === 'successfully') {
            dispatch(setEditFinancialAdvisorFindAvatarStatus('idle'))
            if (financialAdvisorState.editFinancialAdvisorAvatar !== undefined && financialAdvisorState.editFinancialAdvisorAvatar !== null) {
                setAvatarToBase64(financialAdvisorState.editFinancialAdvisorAvatar)
            }
        }
    }, [financialAdvisorState.editFinancialAdvisorFindAvatarStatus])

    if (accountantState.findAllAccountantsWithoutPaginationStatus === 'successfully' && accountantState.findAllAccountantsWithoutPaginationResponse !== undefined) {
        accountantState.findAllAccountantsWithoutPaginationResponse.data
            .forEach(accountant => {
                accountantsMenuItems.push(
                    <MenuItem value={accountant.id}>
                        <AvatarComponent type={AvatarType.USER} size={AvatarSize.XS} name={accountant.name + ' ' + accountant.surname} src="" />
                    </MenuItem>
                )
            })
    }

    return (
        <LayoutComponent
            headingLabel={authState.findMeResponse?.name + ' ' + authState.findMeResponse?.surname}
            menuItem={MenuItems.FINANCIAL_ADVISOR}
            showSpinner={
                financialAdvisorState.findFinancialAdvisorStatus === 'loading' || accountantState.findAllAccountantsWithoutPaginationStatus === 'loading' || authState.editMeFinancialAdvisorComplete === 'loading' || authState.findMeStatus === 'loading' ||
                financialAdvisorState.findFinancialAdvisorStatus === 'failed'
            }
            headingButtons={[
                <ButtonComponent
                    disabled={
                        (authState.editMeFinancialAdvisorRequest.businessName !== undefined && authState.editMeFinancialAdvisorRequest.businessName === '') ||
                        (authState.editMeFinancialAdvisorRequest.name !== undefined && authState.editMeFinancialAdvisorRequest.name === '') ||
                        (authState.editMeFinancialAdvisorRequest.surname !== undefined && authState.editMeFinancialAdvisorRequest.surname === '') || (
                            authState.editMeFinancialAdvisorRequest.phoneNumber !== undefined && authState.editMeFinancialAdvisorRequest.phoneNumber !== null && authState.editMeFinancialAdvisorRequest.phoneNumber !== '' &&
                            (authState.editMeFinancialAdvisorRequest.phoneNumber.length < 9 || authState.editMeFinancialAdvisorRequest.phoneNumber.length > 10)
                        )
                    }
                    key='heading-button-update-accountant'
                    label={"Aggiorna"}
                    onClick={() => {
                        if (file !== null)
                            formData.append('file', file)
                        formData.append('financialAdvisorUpdateDTO', JSON.stringify(authState.editMeFinancialAdvisorRequest))
                        dispatch(editMeFinancialAdvisorComplete({ id: authState.findMeResponse !== undefined ? authState.findMeResponse.id.toString() : '', formData: formData, setAccountant: authState.setAccountantRequest }))
                    }} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.SM} iconStyle={IconStyle.OFF}
                />]}
            breadcrumbItems={['Profilo']}
            headingSize={HeadingSize.LG}
        >
            <ErrorPopup
                active={authState.editMeFinancialAdvisorComplete === 'failed'}
                close={() => dispatch(setEditMeFinancialAdvisorStatus('idle'))}
                message="Si è verificato un errore durante la modifica del commercialista"
            />
            <SuccessPopup
                active={authState.financialAdvisorSuccessPopup}
                close={() => dispatch(setFinancialAdvisorSuccessPopup(false))}
                message="Commercialista modificato"
            />
            <FormGroup required label={"Business name"}
                inputs={[
                    <TextInput id={"financial-advisor-edit-business-name"} defaultValue={authState.findMeResponse?.businessName} onChange={e => dispatch(setEditMeFinancialAdvisorRequestBusinessName(e.target.value))} key={"financial-advisor-edit-name"} type={"text"} placeholder={"Business name"} />
                ]}
                style={"row"}
            />
            <FormGroup required label={"Nome"}
                inputs={[
                    <TextInput id={"financial-advisor-edit-name"} defaultValue={authState.findMeResponse?.name} onChange={e => dispatch(setEditMeFinancialAdvisorRequestName(e.target.value))} key={"financial-advisor-edit-name"} type={"text"} placeholder={"Nome"} />,
                    <TextInput id={"financial-advisor-edit-surname"} defaultValue={authState.findMeResponse?.surname} onChange={e => dispatch(setEditMeFinancialAdvisorRequestSurname(e.target.value))} key={"financial-advisor-edit-surname"} type={"text"} placeholder={"Cognome"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Email"}
                inputs={[
                    <TextInput startIcon={<MailIcon colorBase="" />} defaultValue={authState.findMeResponse?.email} disabled id={"financial-advisor-edit-email"} key={"financial-advisor-edit-email"} type={"text"} placeholder={"Email"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Telefono"}
                inputs={[
                    <TextInput startIcon={<SmartphoneIcon colorBase="" />} infoText="Inserire da 9 a 10 caratteri" defaultValue={authState.findMeResponse?.phoneNumber} onChange={e => dispatch(setEditMeFinancialAdvisorRequestPhoneNumber(e.target.value === '' ? null : e.target.value))} id={"financial-advisor-edit-phone"} key={"financial-advisor-edit-phone"} type={"text"} placeholder={"Telefono"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Avatar"} labelDescription="Verrà visualizzata sul vostro profilo"
                inputs={[
                    <div key={"financial-advisor-edit-avatar-upload"} className="d-flex align-items-center justify-content-center gap-2">
                        <AvatarComponent src={avatarToBase64} key={"financial-advisor-edit-avatar"} type={AvatarType.SINGLE} size={AvatarSize.XXL} />
                        <FileInputComponent onChange={e => { setFile(e[0]); toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data)) }} id={"financial-advisor-edit-upload"} key={"financial-advisor-edit-upload"} />
                    </div>
                ]}
                style={"row"}
            />
            <FormGroup label={"Bio"} labelDescription="Scrivi una breve introduzione"
                inputs={[
                    <TextInput label="Descrizione" defaultValue={authState.findMeResponse?.description} onChange={e => dispatch(setEditMeFinancialAdvisorRequestDescription(e.target.value))} id={"financial-advisor-edit-bio"} key={"financial-advisor-edit-bio"} type={"text"} multiline placeholder={"Un po' di informazioni su di te"} infoText="275 caratteri rimasti" />
                ]}
                style={"row"}
            />
            <FormGroup label={"Account"}
                inputs={[
                    <SelectComponent
                        id={"edit-accountant-admin-select"}
                        onChange={(e) => dispatch(setEditMeFinancialAdvisorRequestAccountantId(e.target.value))}
                        defaultValue={authState.findMeResponse?.accountantId !== null ? authState.findMeResponse?.accountantId?.toString() : ''}
                        menuItems={[accountantsMenuItems]}
                        disabled
                    />
                ]}
                style={"row"}
            />
            <div className="mt-5">
                <FormGroup label={"Gestione Notifiche"}
                    inputs={[
                        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', gap: 40 }}>
                            <SwitchComponent
                                label="Attività"
                                id={"edit-financial-advisor-activity-notifications"}
                                onChange={(_, checked) => dispatch(setEditMeFinancialAdvisorRequestActivityNotifications(checked))}
                                checked={authState.editMeFinancialAdvisorRequest.activityNotifications === undefined ? authState.findMeResponse?.activityNotifications : authState.editMeFinancialAdvisorRequest.activityNotifications}
                            />
                            <SwitchComponent
                                label="Operazioni (messaggi, documenti, conto corrente)"
                                id={"edit-financial-advisor-document-notifications"}
                                onChange={(_, checked) => dispatch(setEditMeFinancialAdvisorRequestOperationsNotifications(checked))}
                                checked={authState.editMeFinancialAdvisorRequest.operationsNotifications === undefined ? authState.findMeResponse?.operationsNotifications : authState.editMeFinancialAdvisorRequest.operationsNotifications}
                            />
                        </div>
                    ]}
                    style={"row"}
                />
            </div>
        </LayoutComponent>
    )
}